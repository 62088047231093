export const encodeWorkOSState = (state: {}): string => {
  try {
    return window.btoa(encodeURIComponent(JSON.stringify(state)));
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const decodeWorkOSState = (
  state: string
): { isOnboardingVariation?: boolean } => {
  try {
    return JSON.parse(decodeURIComponent(window.atob(state)));
  } catch (e) {
    console.error(e);
    return {};
  }
};
