import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { Dropdown, Divider, Button } from 'antd';
import cx from 'classnames';
import { MoreHorizontal, Trash, Share } from 'lucide-react';
import { AssetCard } from './AssetCard';
import { deleteItem } from 'appJS/utils/fetch';
import { DesignAsset } from './types';
import { useDesignAssetsState } from '../../../clients/providers/DesignAssets';
import track from 'appJS/utils/analytics';

type GroupedAssetProps = {
  name: string;
  groups: string[];
  assets: DesignAsset[];
  disabled: boolean;
  showDropdown: boolean;
};

export const GroupedAssets: FC<GroupedAssetProps> = ({
  name,
  assets,
  groups,
  disabled,
  showDropdown
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const {
    onAddToGroup,
    setIsGroupNameModalOpen,
    container,
    setData,
    bugherdUrl,
    onSharingClick,
    isGuest,
    isNoLoginGuestExperienceEnabled
  } = useDesignAssetsState();

  if (assets.length === 0) return null; // don't render empty groups

  const handleVisibleChange = (visible: boolean) => {
    setIsMenuOpen(visible);
    setTimeout(() => setConfirmDelete(false), 300);
  };

  const handleMenuClick = ({ key }) => {
    if (key !== 'delete group' || confirmDelete) setIsMenuOpen(false);
  };

  const handleDelete = async () => {
    if (confirmDelete) {
      const assetPromises = assets.map(async asset => {
        const response = await deleteItem(
          `/projects/${asset.projectId}/assets/${asset.id}`
        );
        if (response.status === 'ok') {
          track('Deleted group');
          // @ts-expect-error
          setData((previousValue: DesignAsset[]): DesignAsset[] =>
            previousValue.filter(
              ({ id }) =>
                id.toString() !==
                `/projects/${asset.projectId}/assets/${asset.id}`
                  .split('/')
                  .slice(-1)[0]
            )
          );
        }
      });
      await Promise.all(assetPromises);
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <span className={styles.confirm}>
          {' '}
          {confirmDelete ? 'Really delete?' : 'Delete entire group'}
        </span>
      ),
      icon: <Trash className={styles.trashIcon} />,
      key: 'delete group',
      onClick: handleDelete
    }
  ];

  const showHeader = groups.length > 0;

  return (
    <>
      {showHeader && (
        <>
          <div className={styles.groupHeader}>
            <h2 className={styles.groupHeaderTitle}>{name}</h2>
            {showDropdown && (
              <div className={styles.groupMenu}>
                <Dropdown
                  className={styles.assetDropdown}
                  getPopupContainer={() => container}
                  trigger={['click']}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                  overlayClassName={styles.overlay}
                  onOpenChange={handleVisibleChange}
                  menu={{ items, onClick: handleMenuClick }}
                  open={isMenuOpen}
                  key={name}
                >
                  <MoreHorizontal
                    onClick={event => event.preventDefault()}
                    className={cx(styles.moreIcon, {
                      [styles.active]: isMenuOpen
                    })}
                  />
                </Dropdown>
                {!disabled && (
                  <Button
                    icon={<Share className={styles.shareGroupIcon} />}
                    type="link"
                    onClick={() => onSharingClick({ assetGroupName: name })}
                  />
                )}
              </div>
            )}
          </div>
          <Divider key={'divider'} />
        </>
      )}
      {assets.map((asset, index) => (
        <AssetCard
          asset={asset}
          key={asset.id}
          container={container}
          canManageAssets
          disabled={disabled}
          setData={setData}
          bugherdUrl={bugherdUrl}
          existingGroups={groups}
          handleSharingClick={() => onSharingClick({ assetId: asset.id })}
          onAddToGroup={onAddToGroup}
          openNewGroupModal={() => setIsGroupNameModalOpen(asset.id)}
          isGuest={isGuest}
          index={index}
          isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
        />
      ))}
    </>
  );
};
