import dataUriToBlob from './dataUriToBlob';

const uploadToS3 = ({
  uploadMetaData,
  uploadAssetPathName,
  dataRecording,
  dataURI,
  onUploadStart,
  onUploadError,
  onUploadComplete,
  onUploadProgress
}) => {
  let form = new FormData();
  form.append('key', uploadAssetPathName);
  form.append('AWSAccessKeyId', 'AKIAIK2PYU6R3QKHBZDA');
  form.append('acl', 'public-read');
  form.append('policy', uploadMetaData.policy);
  form.append('signature', uploadMetaData.signature);
  form.append(
    'Content-Type',
    dataRecording ? dataRecording.type : 'image/jpeg'
  );
  const file = dataRecording?.originFileObj || dataRecording;
  form.append('file', file || dataUriToBlob(dataURI));

  const request = new XMLHttpRequest();
  request.open('POST', uploadMetaData.S3URL, true);

  request.upload.addEventListener('loadstart', () => onUploadStart());
  request.upload.addEventListener('progress', event => onUploadProgress(event));
  request.upload.addEventListener('error', event => onUploadError(event));

  request.addEventListener('abort', event => {
    onUploadError(event);
  });

  request.addEventListener('load', event => {
    const { readyState, status } = event.currentTarget;
    if (readyState === 4) {
      if (status !== 204) {
        onUploadError(event);
      } else {
        onUploadComplete(uploadMetaData.success, uploadAssetPathName);
      }
    }
  });

  request.send(form);
};

export default uploadToS3;
