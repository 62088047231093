import React, { FC, useState } from 'react';
import { message } from 'antd';
import Attachments from '../../../../../clients/sidebar/views/SidebarPanel/Detailbar/Attachments';
import TaskLocation from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/TaskLocation';
import DueDate from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/DueDate';
import { Assignees } from '../../../../../clients/sidebar/views/SidebarPanel/Detailbar/Assignees';
import AdditionalInfo from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/AdditionalInfo';
import Severity from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Severity';
import Tags from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Tags';
import styles from './index.module.css';
import camelCaseKeys from 'camelcase-keys';
import VisibleTo from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/VisibleTo';
import { v4 as uuidv4 } from 'uuid';
import { Props } from './types';

export const TaskInfo: FC<Props> = ({
  isRecording,
  taskUrls,
  priorityId,
  setProperty,
  assignees,
  tagIds,
  allTags,
  dueDate,
  attachments,
  bugherdUrl,
  projectId,
  taskId,
  browserData,
  selector,
  taskMetadata,
  isAdminView,
  container,
  editSeverity,
  editAssignees,
  viewAssignees,
  editTags,
  viewDueDate,
  editDueDate,
  viewSelector,
  attachmentsDisabled,
  gotoTask,
  canEditTaskUrl,
  canManageProject,
  sites,
  projectUrl,
  projectDomains,
  pageUrl,
  guestVisible,
  taskEditVisibility,
  projectSiteId,
  assignGuests,
  assignGuestsAllowed,
  billingRights,
  currentUser,
  users
}) => {
  const [pastedFile, setPastedFile] = useState<File | null>(null);

  const handleUpdateTags = ids => {
    setProperty({
      tag_ids: ids,
      tag_names: allTags
        .filter(tag => ids.includes(tag.get('id')))
        .map(tag => tag.get('name'))
    });
  };

  const handleCreateTag = newTagName => {
    setProperty({
      tag_ids: [...tagIds, null],
      tag_names: allTags
        .filter(tag => tagIds.includes(tag.get('id')))
        .map(tag => tag.get('name'))
        .concat(newTagName)
    });
  };

  const handleRemoveTag = tagId => {
    const _tagIds = tagIds.filter(id => id !== tagId);
    setProperty({
      tag_ids: _tagIds,
      tag_names: allTags
        .filter(tag => _tagIds.includes(tag.get('id')))
        .map(tag => tag.get('name'))
    });
  };

  const handlePaste = e => {
    const copiedFile = e.clipboardData.files;
    const taskIdAndUid = {
      taskId,
      uid: uuidv4()
    };

    const filesWithProperties = Array.from(copiedFile).map(fileObject => {
      const file = new File([fileObject], fileObject.name, {
        type: fileObject.type,
        lastModified: fileObject.lastModified,
        ...taskIdAndUid
      });

      return file;
    });

    if (filesWithProperties.length > 0) {
      setPastedFile(filesWithProperties);
      message.success('File pasted successfully.');
    }
  };

  const getTags = tag => ({
    id: tag.get('id'),
    name: tag.get('name'),
    active: tag.get('active')
  });

  return (
    <div onPaste={handlePaste} className={styles.container}>
      {taskUrls && (
        <TaskLocation
          gotoTask={gotoTask}
          taskPath={taskUrls.taskPath}
          absoluteUrl={taskUrls.absoluteUrl}
          container={container}
          canEditTaskUrl={canEditTaskUrl}
          taskHost={taskUrls.taskHost}
          canManageProject={canManageProject}
          isAdminView={isAdminView}
          sites={sites}
          devurl={projectUrl}
          projectDomains={projectDomains}
          updateSite={site => setProperty({ site })}
          updateUrl={url => setProperty({ url })}
          taskId={taskId}
          pageUrl={pageUrl}
          projectId={projectId}
          bugherdUrl={bugherdUrl}
          projectSiteId={projectSiteId}
        />
      )}

      <Severity
        priorityId={priorityId}
        updateSeverity={value => setProperty({ priority_id: value })}
        canEditSeverity={editSeverity}
      />

      {taskEditVisibility && (
        <VisibleTo
          updateVisibility={visibility =>
            setProperty({ guest_visible: visibility })
          }
          isAdminView={isAdminView}
          guestVisible={!!guestVisible}
        />
      )}

      <Assignees
        container={container}
        canEditAssignees={editAssignees}
        viewAssignees={viewAssignees}
        assignees={assignees}
        updateAssignees={value => setProperty({ assignee_ids: value })}
        assignGuestsAllowed={assignGuestsAllowed}
        bugherdUrl={bugherdUrl}
        assignGuests={assignGuests}
        currentUser={currentUser}
        assignableUsers={
          users &&
          users.filter(
            ({ role, is_on_project }) =>
              role !== 'collaborator' && is_on_project
          )
        }
        billing={billingRights}
        isAdminView={isAdminView}
        guestVisible={!!guestVisible}
      />

      <Tags
        onUpdateTags={handleUpdateTags}
        onCreateTag={handleCreateTag}
        onRemoveTag={handleRemoveTag}
        tags={allTags.filter(tag => tagIds.includes(tag.id)).map(getTags)}
        tagIds={tagIds}
        allTags={allTags.map(getTags)}
        canEditTags={editTags}
      />

      {(editDueDate || viewDueDate) && (
        <DueDate
          dueDate={dueDate}
          container={container}
          canEditDueDate={editDueDate}
          updateDueDate={date => setProperty({ due_at: date })}
        />
      )}

      {!attachmentsDisabled && (
        <Attachments
          attachments={attachments}
          onUpdateAttachments={attachments => setProperty({ attachments })}
          bugherdUrl={bugherdUrl}
          projectId={projectId}
          taskId={taskId}
          isAdminView={isAdminView}
          container={container}
          pastedFiles={pastedFile}
        />
      )}

      <AdditionalInfo
        isRecording={isRecording}
        browserData={camelCaseKeys(browserData, { deep: true })}
        selector={selector}
        absoluteUrl={taskUrls ? taskUrls.absoluteUrl : ''}
        canViewSelector={viewSelector}
        metadata={taskMetadata}
        isAdminView={isAdminView}
      />
    </div>
  );
};
