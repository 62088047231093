import React, { ChangeEventHandler, FC } from 'react';
import { Input, Space } from 'antd';
import * as Types from '../types';
import styles from './index.module.css';
import { useLoginlessDetails } from './useLoginlessDetails';

type Props = {
  show: boolean;
  onNameChange: (name: string) => void;
  onEmailChange: (email: string) => void;
  nameStatus: 'warning' | 'error' | undefined;
  emailStatus: 'warning' | 'error' | undefined;
  user: Types.User;
  onReturningUserChange: () => void;
};

export const LoginlessTaskInfo: FC<Props> = ({
  show,
  emailStatus,
  nameStatus,
  onNameChange,
  onEmailChange,
  onReturningUserChange,
  user: { firstName, email }
}) => {
  const { name, emailAddress, setName, setEmailAddress } = useLoginlessDetails({
    firstName,
    email,
    onReturningUserChange,
    onEmailChange,
    onNameChange
  });

  if (!show) return null;

  const handleNameInput: ChangeEventHandler<HTMLInputElement> = event => {
    setName(event.target.value);
    onNameChange(event.target.value);
  };
  const handleEmailInput: ChangeEventHandler<HTMLInputElement> = event => {
    setEmailAddress(event.target.value);
    onEmailChange(event.target.value);
  };

  return (
    <div className={styles.loginlessInputContainer}>
      <p>Add your info to let the team know who’s giving feedback.</p>
      <Space direction="vertical" className={styles.loginlessInputInner}>
        <Input
          key={'name'}
          placeholder={`First name ${firstName ? '(required)' : ''}`}
          onChange={handleNameInput}
          status={nameStatus}
          value={name}
          required
        />

        <Input
          key={'email'}
          placeholder="name@example.com"
          onChange={handleEmailInput}
          status={emailStatus}
          disabled={!firstName}
          value={emailAddress}
          type="email"
          required
        />
      </Space>
    </div>
  );
};
