import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Form, Input, Button, message as antmessage, Alert } from 'antd';
import { CheckCircle } from 'lucide-react';
import { emit } from 'utils/extensionMessenger';
import { post, get } from 'utils/fetch';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import SocialLogin from '../SocialLogin';

const strings = translations[getLangKey()];

export default ({
  authenticityToken,
  assetPath,
  loginLinkEnabled,
  expiredInviteLink
}: {
  authenticityToken: string;
  assetPath?: string;
  loginLinkEnabled: boolean;
  expiredInviteLink: boolean;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ssoLoading, setSsoLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [countdown, setCountdown] = useState<number | undefined>();
  const location = useLocation();
  const querySearchString = location.search;

  useEffect(() => {
    if (countdown === undefined) {
      return;
    }

    if (countdown === 0) {
      window.close();
      return;
    }
    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');

    if (error) {
      localStorage.removeItem('bugherdSsoOrganizationUrl');
      setErrorMessage(error);
    }
  }, []);

  const queryString: {
    source: string | undefined;
    url: string | undefined;
    redirect_to: string | undefined;
    project_api_key: string | undefined;
    authenticated_email: string | undefined;
    authenticated_name: string | undefined;
    anonymous_user_id: string | undefined;
    loginless: string | undefined;
  } = Object.assign(
    {
      source: undefined,
      url: undefined,
      redirect_to: undefined,
      project_api_key: undefined,
      authenticated_email: undefined,
      authenticated_name: undefined,
      anonymous_user_id: undefined
    },
    qs.parse(window.location.search, { ignoreQueryPrefix: true })
  );

  const handleSuccess = (redirectTo?: string) => {
    emit('bugherdAuthenticated');

    if (queryString.source === 'extension') {
      setSuccessMessage(true);

      setCountdown(3);
    } else if (queryString.source === 'sidebar' && queryString.redirect_to) {
      window.sessionStorage.setItem(
        'bugherdSidebarManuallyAuthenticated',
        'true'
      );

      window.location.href = queryString.redirect_to;
    } else if (queryString.redirect_to) {
      window.location.href = queryString.redirect_to;
    } else {
      window.location.href = redirectTo || '/';
    }
  };

  const onFinish = (values: { email: string; password: string }) => {
    setLoading(true);

    post('/users/sessions', {
      utf8: '✓',
      authenticity_token: authenticityToken,
      project_api_key: queryString.project_api_key,
      asset_path: assetPath || '',
      user: {
        ...values,
        remember_me: 1
      }
    })
      .then(body => {
        if (body.error && body.error.message) {
          throw Error(body.error.message);
        } else {
          handleSuccess(body.redirect_to);
        }
      })
      .catch(({ message }) => {
        setLoading(false);
        if (message === 'HTTP error! status: 403') {
          antmessage.error(strings.emailOrPassword, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  const onSsoFinish = (organization: string, provider: string) => {
    setSsoLoading(true);

    const params = new URLSearchParams(window.location.search);
    const validationToken = params.get('validation_token');
    const projectId = params.get('project_id');
    const orgProvider = organization || provider;
    const urlParams =
      validationToken && projectId
        ? `?validation_token=${validationToken}&project_id=${projectId}`
        : '';

    get(`/users/sign_in/sso/auth/${orgProvider}${urlParams}`, {
      utf8: '✓',
      authenticity_token: authenticityToken
    })
      .then(body => {
        if (body.error) {
          setSsoLoading(false);
        } else {
          localStorage.setItem('bugherdSsoOrganizationUrl', organization);
          window.location.href = body.auth_url;
        }
      })
      .catch(({ message }) => {
        setSsoLoading(false);
        if (message.includes('HTTP error!')) {
          antmessage.error(strings.thereWasAnError, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  const renderSingleSignOn = () => {
    if (localStorage.getItem('bugherdSsoOrganizationUrl')) {
      const organization = localStorage.getItem('bugherdSsoOrganizationUrl');

      return (
        <div>
          <div className={styles.ssoDivider}>
            <span className={styles.ssoDividerText}>{strings.orCaps}</span>
          </div>
          <Button
            className={styles.submitSocialSso}
            type="primary"
            size="large"
            onClick={() => onSsoFinish(organization || '', '')}
            loading={ssoLoading}
          >
            {strings.logInWithSso}
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <div className={styles.ssoDivider}>
            <span className={styles.ssoDividerText}>OR</span>
          </div>
          {loginLinkEnabled && (
            <Link to="/sign_in/login_link">
              <Button
                className={styles.emailLoginLink}
                type="primary"
                size="large"
              >
                {strings.emailMeALoginLink}
              </Button>
            </Link>
          )}
          <SocialLogin authenticityToken={authenticityToken} />
          <Link to="/sign_in/sso">
            <Button
              className={styles.submitSocialSso}
              type="default"
              size="large"
            >
              {strings.logInWithSso}
            </Button>
          </Link>
        </div>
      );
    }
  };

  const clearOrganization = () => {
    localStorage.setItem('bugherdSsoOrganizationUrl', '');
  };

  const renderTitle = () => {
    const orgUrl = localStorage.getItem('bugherdSsoOrganizationUrl');

    if (orgUrl) {
      return (
        <div>
          <h1 className={styles.title}>
            {strings.logInTo}
            <br />
            bugherd.com/{orgUrl}
          </h1>
          <p className={styles.clearOrg}>
            {strings.notYourOrganization}
            <Link to="/sign_in/sso" onClick={() => clearOrganization()}>
              {strings.clickHere}
            </Link>
          </p>
        </div>
      );
    } else {
      return <h1 className={styles.title}>{strings.logInToBugherd}</h1>;
    }
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <Alert
          className={styles.errorMessage}
          message={errorMessage}
          type="error"
          closable
          onClose={() => setErrorMessage(undefined)}
        />
      );
    }
  };

  const renderExpiredInviteWarning = () => {
    if (expiredInviteLink) {
      return (
        <Alert
          className={styles.warning}
          message={strings.expiredInviteLink}
          type="warning"
          closable
          showIcon
        />
      );
    }
  };

  const renderContent = () => {
    if (successMessage) {
      return (
        <div className={styles.hero}>
          <div className={`${styles.inner} ${styles.success}`}>
            <CheckCircle
              size={58}
              color="#1ea92e"
              strokeWidth={3}
              className={styles.successIcon}
            />
            <h1 className={styles.title}>{strings.youreLoggedIn}</h1>
            <p className={styles.closeCopy}>
              {strings.thisWindowWillClose} {countdown} {strings.second}
              {countdown === 1 ? '' : 's'}.
            </p>
          </div>
        </div>
      );
    }

    if (queryString.loginless) {
      return (
        <div>
          <h1 className={styles.title}>{strings.welcome}</h1>
          <p className={styles.intro}>{strings.clickTheContinueButton}</p>
          <div className={styles.center}>
            <Button
              size="large"
              type="primary"
              onClick={() => handleSuccess()}
              className={styles.continueButton}
            >
              {strings.continue}
            </Button>
          </div>
        </div>
      );
    }

    if (queryString.authenticated_email) {
      return (
        <div>
          <h1 className={styles.title}>
            {strings.welcomeBack}
            <br />
            {queryString.authenticated_name}.
          </h1>
          <p className={styles.intro}>{strings.clickTheContinueButton}</p>
          <div className={styles.center}>
            <Button
              size="large"
              type="primary"
              onClick={() => handleSuccess()}
              className={styles.continueButton}
            >
              {strings.continue}
            </Button>
            <p>
              {strings.notYourAccount}
              <br />
              <a href="/users/sign_in">{strings.logInWithAnotherAccount}</a>
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        {renderTitle()}

        {queryString.source === 'sidebar' && queryString.redirect_to && (
          <p className={styles.intro}>
            {strings.logInToStartGivingFeedback}
            <strong>{new URL(queryString.redirect_to).host}</strong>
          </p>
        )}

        {renderErrorMessage()}
        {renderExpiredInviteWarning()}

        <Form
          className={styles.form}
          name="login"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            segment_anonymous_id: queryString.anonymous_user_id,
            segment_source: queryString.source || 'app'
          }}
        >
          <Form.Item hidden name="segment_anonymous_id">
            <Input type="text" />
          </Form.Item>

          <Form.Item hidden name="segment_source">
            <Input type="text" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: strings.pleaseEnterYourEmail }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: strings.pleaseEnterYourPassword }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className={styles.rightAlign}>
            <Link to="/passwords/new">{strings.forgotYourPassword}</Link>
          </div>
          <div className={styles.center}>
            <Form.Item noStyle>
              <div>
                <Button
                  className={styles.submit}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  {strings.logIn}
                </Button>
              </div>
            </Form.Item>
            {renderSingleSignOn()}
          </div>
        </Form>
        <div className={styles.newToBugherd}>
          {strings.newToBugherd}
          <a href={`/users/registrations/new${querySearchString}`}>
            {strings.signUp}
          </a>
        </div>
        <div className={styles.backToWebsite}>
          <a href="https://bugherd.com">{strings.backToWebsite}</a>
        </div>
      </div>
    );
  };

  return renderContent();
};
