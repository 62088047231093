import * as React from 'react';
import { Button, Input, Switch, message, Form } from 'antd';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import HelpArticleButton from 'utils/HelpArticleButton';
import styles from './index.module.css';
import { createProject } from 'api/ProjectApi';
import ProjectUrlInput from 'components/projectUrlInput';
import { updateExtension } from 'appJS/utils/updateExtension';

interface Props {
  organizationId: number;
  guestsSeeGuests: boolean;
  guestsEditTaskStatus: boolean;
  hasNewOnboarding: boolean;
}

const { Item } = Form;

const strings = translations[getLangKey()];

const CreateNewProject: React.FC<Props> = props => {
  const {
    organizationId,
    guestsSeeGuests,
    guestsEditTaskStatus,
    hasNewOnboarding
  } = props;
  const [updateError, setUpdateError] = React.useState<string>('');
  const [projectName, setProjectName] = React.useState<string>('');
  const [saving, setSaving] = React.useState<boolean>(false);
  const [permission, setPermission] = React.useState<boolean>(guestsSeeGuests);
  const [
    allowGuestsChangeTaskStatus,
    setAllowGuestsChangeTaskStatus
  ] = React.useState<boolean>(guestsEditTaskStatus);
  const [projectUrl, setProjectUrl] = React.useState<string>('');

  const submitProjectData = async () => {
    setSaving(true);

    try {
      const response = await createProject({
        organizationId,
        project: {
          name: projectName,
          devurl: projectUrl,
          permission: permission ? 'guests_see_guests' : 'guests_see_self',
          allow_guests_change_task_status: allowGuestsChangeTaskStatus
        }
      });

      if (response && response.status === 'ok') {
        updateExtension();
        window.location.pathname = hasNewOnboarding
          ? '/projects/' + response.project_id + '/assets'
          : '/projects/' + response.project_id;
      } else {
        message.error(strings.createError);
        setSaving(false);
      }
    } catch (error) {
      setSaving(false);
      setUpdateError(error?.message);
    }
  };

  const [form] = Form.useForm();

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (await form.validateFields(['WebsiteURL'])) submitProjectData();
    else return;
  };

  const container = document.getElementById('create_new_project_container');

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={submit}
      hideRequiredMark
      className={styles.formContainer}
    >
      <Form.Item
        label={<span>{strings.nameLabel}:</span>}
        name={'projectName'}
        initialValue={projectName}
        rules={[
          {
            type: 'string',
            message: 'This is not a valid name'
          },
          {
            required: true,
            message: strings.emptyValidationMessage
          }
        ]}
      >
        <Input
          placeholder={strings.namePlaceholder}
          className={styles.nameInput}
          autoFocus
          onChange={event => setProjectName(event.target.value)}
        />
      </Form.Item>
      <div className={styles.projectUrl}>
        <label className={styles.label}>
          <span className={styles.urlLabel}>
            {hasNewOnboarding ? strings.urlOptionalLabel : strings.urlLabel}:
          </span>
          <HelpArticleButton articleId={strings.urlHelpArticleId} />
        </label>
        <br />
        <ProjectUrlInput
          fieldName={['WebsiteURL']}
          currentUrl={projectUrl}
          onChange={(newUrl: string) => {
            setProjectUrl(newUrl);
          }}
          isUrlRequired={!hasNewOnboarding}
          showUrlPlaceHolder
          container={container || undefined}
          checkUrlOnEnter
        />
        <p>{strings.urlHint}</p>
      </div>
      {guestsSeeGuests && (
        <Item className={styles.switchItem}>
          <Switch
            checked={permission}
            onChange={setPermission}
            className={styles.switch}
          />
          <span className={styles.guestsMessage}>
            {strings.guestsPermissionMessage}{' '}
            <HelpArticleButton articleId={strings.guestsHelpArticleId} />
          </span>
        </Item>
      )}
      {guestsEditTaskStatus && (
        <Item className={styles.switchItem}>
          <Switch
            checked={allowGuestsChangeTaskStatus}
            onChange={setAllowGuestsChangeTaskStatus}
            className={styles.switch}
          />
          <span className={styles.guestsMessage}>
            {strings.allowGuestChangeTaskStatus}{' '}
            <HelpArticleButton articleId={strings.guestsHelpArticleId} />
          </span>
        </Item>
      )}
      <Item>
        <Button type="primary" htmlType="submit" loading={saving}>
          {strings.createProject}
        </Button>
        <p className={styles.jsError}>{updateError}</p>
      </Item>
    </Form>
  );
};

export default CreateNewProject;
