import { useState, useEffect } from 'react';
import { getPreference, setPreferences } from '../utils/getPreferences';
import track from '../../../../redesign/javascript/utils/analytics';

type Parameters = {
  isNewSidebar?: boolean;
  projectId: number;
  canCreatePrivateComments: boolean;
  userId: number;
  organizationId: number;
};

const useCommentPreference = ({
  projectId,
  isNewSidebar,
  canCreatePrivateComments,
  organizationId,
  userId
}: Parameters) => {
  const storageKey: string = `sidebar_project_${projectId}`;
  const [isPrivatePreference, setIsPrivatePreference] = useState<
    boolean | undefined
  >();
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>();
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);

  const getCommentPreference = () => {
    const settings = JSON.parse(localStorage.getItem(storageKey) || '{}');
    return !!settings.isPrivateCommentType;
  };

  const initPreferences = () => {
    (async () => {
      let preference: any = getCommentPreference();
      if (isNewSidebar) {
        preference = await getPreference({
          projectId,
          preference: 'isPrivateCommentType'
        });
        if (typeof preference !== 'boolean') {
          preference = false;
        }
      }
      setIsPrivatePreference(preference);
      setIsPrivate(canCreatePrivateComments ? preference : false);
    })();
  };

  useEffect(initPreferences, []);

  const handlePrivateToggle = (checked: boolean) => {
    if (checked && !canCreatePrivateComments) {
      setShowUpgrade(!showUpgrade);
    } else if (checked !== isPrivate) {
      if (isNewSidebar) {
        setPreferences({
          projectId,
          preferences: { isPrivateCommentType: checked }
        });
      } else {
        localStorage.setItem(
          storageKey,
          JSON.stringify(
            Object.assign(
              JSON.parse(localStorage.getItem(storageKey) || '{}'),
              {
                isPrivateCommentType: checked
              }
            )
          )
        );
      }
      if (isPrivatePreference !== checked) setIsPrivatePreference(checked);
      if (isPrivate !== checked) setIsPrivate(checked);
      track(`Comment type: ${checked ? 'private' : 'public'}`, {
        organization: organizationId,
        user: userId
      });
    }
  };

  return {
    handlePrivateToggle,
    isPrivate: !!isPrivate,
    showUpgrade,
    setShowUpgrade
  };
};

export default useCommentPreference;
