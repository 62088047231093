import React, { FC } from 'react';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../clients/sidebar/models/Language';
import { DatePicker } from 'antd';
import DetailsLabel from '../DetailsLabel';
import styles from './styles.module.css';
import { isMobile } from 'react-device-detect';
dayjs.extend(utc);

const strings = translations[getLangKey()];

type Props = {
  dueDate: string;
  canEditDueDate: boolean;
  updateDueDate: (value?: string) => void;
};

const DueDate: FC<Props> = ({ dueDate, updateDueDate, canEditDueDate }) => {
  const handleOnChange = (date: dayjs.Dayjs) => {
    updateDueDate(date ? date.utc().toISOString() : '');
  };

  return (
    <div className={styles.dueDateOuter}>
      <DetailsLabel label={strings.dueDate} />
      {/*// @ts-ignore*/}
      <DatePicker
        inputReadOnly={isMobile}
        value={dueDate ? dayjs(dueDate) : null}
        format="D MMM YYYY"
        onChange={handleOnChange}
        disabled={!canEditDueDate}
        getPopupContainer={trigger => trigger.parentElement}
        className={styles.datePicker}
      />
    </div>
  );
};

export default DueDate;
