// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import { message } from 'antd';
export { default as ReactDOM } from 'react-dom';
export { default as React } from 'react';

import propListenerRender from '../utils/propListenerRender';
import '../app/showNotice';
export { trackPage } from '../utils/analytics';
export { default as extensionMessenger } from '../utils/extensionMessenger';

import ProjectSettings from '../views/project_settings';
import IntegrationSettings from '../views/integration_settings';
import Settings from '../views/settings';
import CommitHook from '../views/commit_hook';
import Login from '../views/users/login';
import SignupCaptureUrl from '../views/users/sign_up_capture_url';
import SingleSignOn from '../views/users/single_sign_on';
import RegisterSsoPage from '../views/users/register_sso_page';
import ResetPassword from '../views/reset_password';
import Users from '../views/users';
import Onboarding from '../views/onboarding';
import MobileOnboarding from '../views/mobile_onboarding';
import Navbar from '../views/navbar';
import InstallBugherd from '../views/install_bugherd';
import OnboardingSurvey from '../views/onboardingSurvey';
import CreateNewProject from '../components/CreateNewProject';
import ProjectBoard from '../views/project_board';
import UserOnboarding from '../views/user_onboarding';
import AssetGroup from '../views/design_feedback/AssetGroup';
import FeedbackRouter from '../views/design_feedback/DesignFeedback';
import FilterAndSort from '../views/FilterAndSort';
import ProjectSiteFeedback from '../views/project_site_feedback';
import { Sharing } from '../views/sharing';

export { default as ReactApp } from '../app';
export { default as DesignAssetFeedback } from '../../clients/design_assets';
export { default as AssetGroup } from '../views/design_feedback/AssetGroup';
export { default as DesignFeedback } from '../views/design_feedback/DesignFeedback';
export { default as ReactTask } from '../views/task_details';
export { default as ReactCreateTask } from '../../../clients/sidebar/views/CreateTask/create_task';
export { default as ReactSidebarNav } from '../views/sidebar_nav';
export { default as ReactKanban } from '../views/kanban';
export { default as TaskList } from '../views/kanban/task_list';
export { default as PinTooltip } from '../components/pinTooltip';

import * as Experiment from '../models/Experiment';
export { Experiment };

import * as Project from '../models/Project';
export { Project };

import { composeTaskLocation } from '../../../clients/shared/utils/taskLocation';
export { composeTaskLocation };

propListenerRender({
  host: 'project_board',
  App: ProjectBoard,
  waitForRedux: true
});

propListenerRender({
  host: 'sharing',
  App: Sharing
});

propListenerRender({
  host: 'integration_settings',
  App: IntegrationSettings,
  listenForPropChanges: true
});

propListenerRender({
  host: 'project_settings',
  App: ProjectSettings,
  listenForPropChanges: true
});

propListenerRender({
  host: 'settings',
  App: Settings,
  listenForPropChanges: true
});

propListenerRender({
  host: 'commit_hook',
  App: CommitHook
});

propListenerRender({
  host: 'login',
  App: Login
});

propListenerRender({
  host: 'capture_url_sign_up',
  App: SignupCaptureUrl
});

propListenerRender({
  host: 'single_sign_on',
  App: SingleSignOn
});

propListenerRender({
  host: 'user_onboarding',
  App: UserOnboarding
});

propListenerRender({
  host: 'onboarding',
  App: Onboarding
});

propListenerRender({
  host: 'mobile_onboarding',
  App: MobileOnboarding
});

propListenerRender({
  host: 'register_sso_page',
  App: RegisterSsoPage
});

propListenerRender({
  host: 'reset_password',
  App: ResetPassword
});

propListenerRender({
  host: 'users',
  App: Users
});

propListenerRender({
  host: 'navbar',
  App: Navbar
});

propListenerRender({
  host: 'install_bugherd',
  App: InstallBugherd
});

propListenerRender({
  host: 'onboarding_survey',
  App: OnboardingSurvey
});

propListenerRender({
  host: 'create_new_project',
  App: CreateNewProject
});

propListenerRender({
  host: 'asset_group',
  App: AssetGroup
});

propListenerRender({
  host: 'feedback_router',
  App: FeedbackRouter
});

propListenerRender({
  host: 'filter_and_sort',
  App: FilterAndSort
});

propListenerRender({
  host: 'project_sites',
  App: ProjectSiteFeedback
});

document.addEventListener('DOMContentLoaded', function(event) {
  let messageId = 'antd_message_container';
  let messageHost = document.getElementById(messageId);

  if (!messageHost) {
    messageHost = document.createElement('div');
    messageHost.id = messageId;
    messageHost.className = 'antd-container';
    document.body.appendChild(messageHost);
  }

  message.config({
    getContainer: () => messageHost
  });
});

export function showErrorNotification(config) {
  message.error(config);
}

export function showInfoNotification(config) {
  message.info(config);
}
