import React, { FC } from 'react';
import { Input, Button } from 'antd';
import styles from './index.module.css';
import { X as Close, Loader } from 'lucide-react';
// @ts-expect-error
import figmaGif from './assets/figma-file-link.gif';
import * as translations from './strings';
import cx from 'classnames';
import { getLangKey } from '../../../clients/sidebar/models/Language';
import animationStyles from '../../../clients/sidebar/views/CreateTask/create_task/overlay/animation.module.css';
import { useFigmaUploadState } from '../../../clients/providers/FigmaUpload';
import {
  useDesignAssetsState,
  ASSETS_SUPPORT_ARTICLE_ID
} from '../../../clients/providers/DesignAssets';
import HelpArticleButton from 'appJS/utils/HelpArticleButton';

const strings = translations[getLangKey()];

export const FigmaURL: FC = () => {
  const {
    onInputChange,
    hasError,
    inputValue,
    getNodeImageURLs,
    isLoading,
    loadingNodes,
    fileKey,
    setFileKey
  } = useFigmaUploadState();
  const { setIsFigmaModalOpen } = useDesignAssetsState();

  const closeModal = () => {
    setIsFigmaModalOpen(false);
    setFileKey('');
  };

  return (
    <>
      <h2>{strings.linkFigma}</h2>
      <div className={styles.closeContainer} onClick={closeModal}>
        <Close className={styles.closeIcon} />
      </div>
      <div className={styles.contentWrapper}>
        <span className={styles.labelWrapper}>
          <p className={styles.label}>{strings.figmaFormLabel}</p>
          <HelpArticleButton articleId={ASSETS_SUPPORT_ARTICLE_ID} />
        </span>

        <Input
          className={styles.urlInput}
          placeholder="Figma URL"
          onChange={onInputChange}
          maxLength={300}
          autoFocus
        />
        {hasError && inputValue && (
          <div className={styles.errorMessage}>
            <p> {strings.figmaURLError}</p>
          </div>
        )}

        <div className={styles.figmaButtonWrapper}>
          <Button
            type="primary"
            onClick={getNodeImageURLs}
            className={styles.syncButton}
            disabled={!fileKey || loadingNodes}
          >
            <Loader
              className={cx(styles.loadingSpinnerSync, {
                [animationStyles.loaderIcon]: loadingNodes,
                [styles.loadingSpinnerShow]: loadingNodes
              })}
            />
            Sync
          </Button>
          <Button
            type="link"
            onClick={closeModal}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>

        <div className={styles.gifWrapper}>
          <img
            src={figmaGif}
            alt="Gif on how to where to find the Figma prototype url"
            width="450"
            height="240"
          />
          <p className={styles.gifText}>{strings.imageCaption}</p>
        </div>
      </div>
      <div
        className={cx(styles.loadingWrapper, {
          [styles.modalVisible]: isLoading
        })}
      >
        <Loader className={animationStyles.loaderIcon} />
        <p>Uploading Figma file. This may take a moment.</p>
      </div>
    </>
  );
};
