import {
  Sites,
  ProjectSite
} from '../../../../../../../clients/shared/utils/taskLocation/types';
import { matchProjectSite } from '../../../../../../../clients/shared/utils/taskLocation/matchProjectSite';

const protocols: string[] = ['http:', 'https:'];

export const isValidSite = (_site?: string | null): boolean => {
  try {
    if (!_site) return false;
    const taskUrl: URL = new URL(_site);
    const hasValidProtocol: boolean = !!protocols.find(
      protocol => protocol === taskUrl.protocol
    );
    const lastIndex: number = taskUrl.hostname.length - 1;
    const noEndingSlash: boolean = taskUrl.hostname[lastIndex] !== '/';
    const noEndingPeriod: boolean = taskUrl.hostname[lastIndex] !== '.';
    const hasTLD: boolean =
      taskUrl.hostname !== 'localhost'
        ? taskUrl.hostname.split('.').length > 1
        : true;
    const noWhiteSpace: boolean =
      !taskUrl.hostname.includes(' ') && !taskUrl.protocol.includes(' ');

    return (
      hasValidProtocol &&
      noEndingSlash &&
      noEndingPeriod &&
      hasTLD &&
      noWhiteSpace
    );
  } catch (error) {
    return false;
  }
};

const getVariants = (_url: URL): { variants: Sites } => {
  const { pathname, protocol, search, port } = _url;
  const host = _url.host.split(':')[0];
  const isSecure: boolean = protocol === 'https:';
  const variant: ProjectSite = {
    scheme: protocol.slice(0, protocol.length - 1),
    host,
    port,
    path: pathname,
    query: search.slice(1),
    fragment: undefined,
    isQuerySignificant: false,
    isFragmentSignificant: false
  };

  const variantScheme: ProjectSite = Object.assign({}, variant, {
    scheme: isSecure ? 'http' : protocol
  });
  const variantHost: ProjectSite = Object.assign({}, variant, {
    host: host.indexOf('www.') !== 0 ? 'www.' + host : host.slice(4)
  });
  const variantHostScheme: ProjectSite = Object.assign({}, variantScheme, {
    host: host.indexOf('www.') !== 0 ? 'www.' + host : host.slice(4)
  });

  return { variants: [variant, variantScheme, variantHost, variantHostScheme] };
};

const flatSites = (pageOrProjectSites: any): ProjectSite[] =>
  // @ts-ignore
  pageOrProjectSites.reduce(
    // Old sidebar has [{variants: Site}] new sidebar has [{Site}]
    // @ts-ignore
    (previous, current) => previous.concat(current.variants || current),
    []
  );

export const matchesProjectUrls = (
  pageOrProjectSites: any,
  taskHost: string,
  taskPath: string
): boolean =>
  !!flatSites(pageOrProjectSites).filter((site: ProjectSite) =>
    matchProjectSite({ site: site, browserUrl: new URL(taskHost + taskPath) })
  ).length;

export default (
  devurl: string,
  projectDomains: string | string[] = []
): { variants: Sites }[] => {
  const sites: { variants: Sites }[] = [];
  const domainIsString = typeof projectDomains === 'string';
  let domains: string[] = domainIsString ? [] : projectDomains;

  if (isValidSite(devurl)) {
    sites.push(getVariants(new URL(devurl)));
  }

  if (domainIsString) {
    domains = projectDomains
      .split('\n')
      .filter(domain => !!domain)
      .map(domain => protocols[0] + domain);
  }

  if (domains.length) {
    domains.forEach(domain => {
      if (isValidSite(domain)) {
        sites.push(getVariants(new URL(domain)));
      }
    });
  }

  return sites;
};
