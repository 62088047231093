// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import uploadToS3 from '../../../../../../redesign/javascript/utils/uploadToS3';
import {
  getFileListCopy,
  getFileInfo
} from '../../../../../../redesign/javascript/utils/fileListOperations';
import { Dragger } from '../../../../../../redesign/javascript/utils/uploadDragListeners';

import { Paperclip as Attach, Upload as UploadIcon } from 'lucide-react';
import { message, Upload } from 'antd';

import styles from './index.module.css';

const cx = classnames.bind(styles);

export default class AttachFile extends React.Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    modalContainer: PropTypes.object,
    modalOuter: PropTypes.object,
    setUploadingState: PropTypes.func.isRequired,
    onFileListChange: PropTypes.func.isRequired,
    fileList: PropTypes.array.isRequired,
    removeFile: PropTypes.func.isRequired,
    pastedFile: PropTypes.object
  };

  handleOnChange = file => {
    if (!this.validateSize(file)) {
      message.error(
        `Sorry, the file (${file.name}) is too large. Maximum file size is 20mb.`
      );
      return;
    }
    const _file = getFileInfo(file);
    let fileListClone = getFileListCopy(this.props.fileList);
    fileListClone.push(_file);
    this.props.onFileListChange(fileListClone);
    const uploadParameters = {
      file: _file,
      remove: this.props.removeFile,
      endpoint: this.props.endpoint,
      additionalQueryParams: { new_task: true, filename: _file.name },
      feedback: message.error,
      onStart: this.setUploadingStatus,
      onProgress: this.onProgress,
      onError: this.onError,
      onComplete: this.onComplete
    };
    uploadToS3(uploadParameters);
  };

  updateFileInList = (uid, data) => {
    let fileListClone = getFileListCopy(this.props.fileList);
    const file = fileListClone.find(file => file.uid === uid);
    Object.assign(file, data);
    this.props.onFileListChange(fileListClone);
  };

  onProgress = ({ loaded, total, lengthComputable }, file) => {
    if (lengthComputable) {
      let percent = Math.round((loaded / total) * 100);
      this.updateFileInList(file.uid, {
        percent,
        status: percent !== 100 ? 'uploading' : 'done'
      });
    }
  };

  onError = (event, file) => {
    message.error('Something went wrong, please try again.');
    this.removeFile(file);
    this.setUploadingStatus();
  };

  setUploadingStatus = () => {
    const { fileList } = this.props;
    let isUploading = false;
    if (fileList.length !== 0) {
      isUploading =
        fileList.filter(file => file.status === 'uploading').length > 0;
    }
    this.props.setUploadingState(isUploading);
  };

  onComplete = (_file, S3URL, key) => {
    this.updateFileInList(_file.uid, { status: 'done', url: S3URL + key });
    this.props.onFileListChange(this.props.fileList);
    this.setUploadingStatus();
  };

  validateSize = file => file.size < 20485760;
  prevPastedFile = null;

  render() {
    const { fileList } = this.props;
    const pastedFile = this.props.pastedFile;

    if (pastedFile && pastedFile !== this.prevPastedFile) {
      this.handleOnChange(pastedFile);
      this.prevPastedFile = pastedFile;
    }

    return (
      <Dragger
        container={this.props.modalContainer}
        prevent={this.props.modalOuter}
        handleOnChange={this.handleOnChange}
      >
        {isDragging => (
          <div
            className={cx({
              attachFileContainer: true
            })}
          >
            <Upload
              name="file"
              fileList={fileList}
              onChange={event => this.handleOnChange(event.file.originFileObj)}
              onRemove={this.props.removeFile}
              showUploadList={false}
              customRequest={() => {}}
              multiple
            >
              <span className={styles.attachContainer}>
                <Attach className={styles.attachIcon} />
              </span>
            </Upload>
            <div className={cx({ overlayBox: true, isDragging })}>
              <div className={styles.overlayContent}>
                <div className={styles.iconBoxLarge}>
                  <UploadIcon className={styles.uploadIcon} />
                </div>
                <div className={styles.dragAndDrop}>
                  <p className={styles.instruction}>
                    Drag & drop to upload a file
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dragger>
    );
  }
}
