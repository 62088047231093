import React, { FC, useState } from 'react';
import { Dropdown, Button } from 'antd';
import { ChevronUp as Up, ChevronDown as Down } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from '../../../../../models/Language';

import styles from './styles.module.css';
import { MoreMenu } from './MoreMenu';

type Props = {
  isAdminView?: boolean;
  container: HTMLDivElement;
  projectId: number;
  taskDelete: boolean;
  taskMoveToProject: boolean;
  shareUrl: string;
  moveToProject: (projectId: number, domEvent?: any) => void;
  deleteTask: (
    onError?: (error: Error) => void,
    onSuccess?: () => void
  ) => void;
  isNewSidebar?: boolean;
};

const strings = translations[getLangKey()];

const More: FC<Props> = ({
  isAdminView,
  container,
  projectId,
  taskDelete,
  taskMoveToProject,
  shareUrl,
  moveToProject,
  deleteTask,
  isNewSidebar
}) => {
  const [open, _setOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const setOpen = (isOpen: boolean) => {
    if (!isOpen && confirmDelete) setConfirmDelete(false);
    _setOpen(isOpen);
  };

  return (
    <Dropdown
      // @ts-ignore
      type="secondary"
      getPopupContainer={() => container}
      trigger={['click']}
      className={styles.antdDropdownOverride}
      placement="bottomRight"
      overlay={
        <MoreMenu
          isAdminView={isAdminView}
          isNewSidebar={isNewSidebar}
          shareUrl={shareUrl}
          taskDelete={taskDelete}
          taskMoveToProject={taskMoveToProject}
          moveToProject={moveToProject}
          deleteTask={deleteTask}
          projectId={projectId}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      }
      open={open}
      onOpenChange={setOpen}
    >
      <Button className={styles.moreActionsButton}>
        <span className={styles.moreActionsInner}>{strings.more}</span>
        {open ? (
          <Up className={styles.menuToggleIcon} />
        ) : (
          <Down className={styles.menuToggleIcon} />
        )}
      </Button>
    </Dropdown>
  );
};

export default More;
