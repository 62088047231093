import React, { FC } from 'react';
import { Settings, Zap } from 'lucide-react';
import styles from './index.module.css';
import cx from 'classnames';
import { Button, Tooltip, Tag } from 'antd';
import premiumFeatureImage from './images/img-upgrade-integrations.jpg';
import ArrowUp from './images/ic-upgrade-circle.svg';
import UpgradePopover from '../../../../clients/sidebar/components/UpgradePopover';

type Props = {
  mobileView?: boolean;
  name: string;
  link: string;
  articleId: string;
  video: boolean;
  isConnectedButton: boolean;
  isPremiumIntegration: boolean;
  integrationName: string;
  enabled: boolean;
  buttonDisabled: boolean;
  onButtonClick: () => void;
  isTrialUser: boolean;
  paragonAllowed: boolean;
  setPopOverToShow: (integrationName: string) => void;
  popOverToShow: string;
  ownerEmail: string;
  bugherdUrl: string;
  organizationId: string;
  setShowPreview: (showPreview: string) => void;
};

export const IntegrationActions: FC<Props> = ({
  mobileView,
  name,
  link,
  articleId,
  video,
  isConnectedButton,
  isPremiumIntegration,
  integrationName,
  enabled,
  buttonDisabled,
  onButtonClick,
  isTrialUser,
  paragonAllowed,
  setPopOverToShow,
  popOverToShow,
  ownerEmail,
  bugherdUrl,
  organizationId,
  setShowPreview
}) => {
  return (
    <div
      className={cx(styles.integrationActions, {
        [styles.mobileActionsView]: mobileView
      })}
    >
      {(link || articleId) && (
        <Button
          type="text"
          className={styles.guideButton}
          href={link}
          target={link ? '_blank' : undefined}
          data-elevio-article={articleId}
          data-elevio-style="nothing"
          onClick={e => {
            if (video && link) {
              e.preventDefault();
              e.stopPropagation();
              setShowPreview(link);
            }
          }}
        >
          {video ? 'See it in action' : 'Overview'}
        </Button>
      )}
      <Button
        type={isConnectedButton ? 'default' : 'primary'}
        className={cx({
          [styles.connectButton]: !isConnectedButton
        })}
        onClick={onButtonClick}
        disabled={buttonDisabled}
        icon={
          enabled ? (
            <Settings className={styles.connectedButtonIcon} />
          ) : (
            <Zap className={styles.disconnectedButtonIcon} />
          )
        }
      >
        <span className={styles.upgradePlanIcon} />
        <span className={styles.buttonText}>
          {enabled ? 'Manage' : 'Connect'}
        </span>
      </Button>
      {isPremiumIntegration && (isTrialUser || !paragonAllowed) && (
        <UpgradePopover
          isSmallView={false}
          open={popOverToShow === name}
          onOpenChange={() => setPopOverToShow('')}
          message={
            <p className={styles.upgradeMessage}>
              {isTrialUser
                ? 'This integration is enabled for your free trial but requires the premium plan after that.'
                : 'To connect this integration, please upgrade to the Premium plan.'}
            </p>
          }
          placement="topRight"
          className={styles.upgradePopover}
          getContainer={() =>
            // @ts-expect-error
            document.getElementById('integration-' + popOverToShow)
          }
          imageSrc={premiumFeatureImage}
          billingRights
          ownerEmail={ownerEmail}
          href={`${bugherdUrl}/organizations/${organizationId}/subscription`}
        >
          <ArrowUp
            className={styles.upgradeArrow}
            onClick={() => setPopOverToShow(integrationName)}
          />
        </UpgradePopover>
      )}
    </div>
  );
};
