import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { Dropdown, Menu } from 'antd';
import { AssetsUpload } from './AssetsUpload';
import { useDesignAssetsState } from '../../../clients/providers/DesignAssets';
import Loader from '../sidebar/components/Loader';
import track from 'jsUtilities/analytics';
import { AddButton } from 'appJS/views/project_board/project_header/AddButton';

export const UploadDropdown: FC = () => {
  const {
    handleOpenFigma,
    checkingToken,
    project,
    container
  } = useDesignAssetsState();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleTracking = () => {
    track('Asset button clicked', { project_id: project?.id });
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        onOpenChange={(visible: boolean) => setIsMenuOpen(visible)}
        open={isMenuOpen}
        placement="bottomRight"
        arrow={false}
        overlay={
          <Menu
            onClick={() => {
              setIsMenuOpen(false);
              handleTracking();
            }}
          >
            <Menu.Item
              key="figma-file"
              onClick={handleOpenFigma}
              className={styles.menuItem}
            >
              <div className={styles.menuInner}>Add Figma file</div>
            </Menu.Item>
            <Menu.Item key="image-pdf-upload" className={styles.menuItem}>
              <AssetsUpload>Upload image or PDF</AssetsUpload>
            </Menu.Item>
          </Menu>
        }
      >
        <AddButton
          icon={checkingToken && <Loader className={styles.loaderIcon} />}
        />
      </Dropdown>
    </>
  );
};
