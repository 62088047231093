export const en = {
  emailOrPassword: 'Email or password were incorrect, please try again.',
  thereWasAnError: 'There was an error, please try again.',
  orCaps: 'OR',
  logInWithSso: 'Log in with SSO',
  logInWithGoogle: 'Continue with Google',
  logInWithMicrosoft: 'Continue with Microsoft',
  logInTo: 'Log in to',
  notYourOrganization: 'Not your organization? ',
  clickHere: 'Click here',
  logInToBugherd: 'Log in to BugHerd',
  youreLoggedIn: 'You’re logged in',
  thisWindowWillClose: 'This window will close in a ',
  second: 'second',
  welcome: 'Welcome',
  welcomeBack: 'Welcome back,',
  clickTheContinueButton:
    "Click the 'Continue' button below to start giving feedback.",
  continue: 'Continue',
  notYourAccount: 'Not your account?',
  logInWithAnotherAccount: 'Log in with another account',
  logInToStartGivingFeedback: 'Log in to start giving feedback on ',
  pleaseEnterYourEmail: 'Please enter your email',
  pleaseEnterYourPassword: 'Please enter your password',
  forgotYourPassword: 'Forgot your password?',
  logIn: 'Log in',
  newToBugherd: 'New to BugHerd?',
  signUp: 'Sign up',
  emailMeALoginLink: 'Email me a login link',
  logInWithEmail: 'Log in with email',
  checkYourEmail: 'Check your email 📬',
  loginLinkDescription: `We've sent a login link to your email. Use this to log in to BugHerd securely.`,
  cantAccessYourEmail: `Can't access your email?`,
  logInWithPassword: 'Log in with password',
  expiredErrorMessage:
    'This login link has expired. To retrieve a new login link, enter your email address.',
  expiredInviteLink:
    'Your invite link has expired. Please contact your project owner.',
  backToBugherd: 'Back to BugHerd',
  backToWebsite: 'Back to website'
};

export type Translations = typeof en;
