import React, { FC } from 'react';
import { format } from 'date-fns';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../clients/sidebar/models/Language';
import styles from './styles.module.css';

const strings = translations[getLangKey()];

type Props = {
  createdAt: string;
  email?: string;
  display: string;
};

const ReportedBy: FC<Props> = ({ createdAt, email, display }) => {
  return (
    <div className={styles.createdContainer}>
      <span className={styles.created}>
        <span
          className={styles.createdTime}
          title={new Date(createdAt).toString()}
        >
          {`${strings.reported} ${format(
            new Date(createdAt),
            "MMM d, yyyy 'at' h:mmaaa"
          )}, ${strings.by} `}
          {email ? (
            <a
              title={display}
              href={`mailto:${email}`}
              className={styles.creator}
            >
              {display}
            </a>
          ) : (
            <span className={styles.creator} title={display}>
              {display}
            </span>
          )}
        </span>
      </span>
    </div>
  );
};

export default ReportedBy;
