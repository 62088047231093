import React, { FC, useState, MutableRefObject } from 'react';
import cx from 'classnames';
import { Eye } from 'lucide-react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Switch, Popover, Tooltip } from 'antd';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../models/Language';
import track from '../../../../../../../../../redesign/javascript/utils/analytics';
import styles from './styles.module.css';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SwitchSize } from 'antd/lib/switch';
import UpgradePopover from '../../../../../../../components/UpgradePopover';
import premiumFeatureImage from '../../../../../../../../../redesign/javascript/assets/images/private_comments_premium_feature.png';
import ArrowUp from '../../../../../../../assets/images/ic-upgrade-circle.svg';

type Props = {
  isPrivate: boolean;
  getContainer: () => HTMLElement;
  canCreatePrivateComments: boolean;
  handleFocus: () => void;
  mentionRef: MutableRefObject<HTMLInputElement | null>;
  uiSize: SizeType;
  setShowUpgrade: (showUpgrade: boolean) => void;
  showUpgrade: boolean;
  handlePrivateToggle: (checked: boolean) => void;
  hasInvalidMentions: boolean;
  currentUserId: string | number;
  organizationId: string | number;
  billingRights: boolean;
  ownerEmail?: string;
  bugherdUrl: string;
  hideLabel?: boolean;
  onPlanUpgradeClick?: () => void;
};

const strings = translations[getLangKey()];

const PrivateToggle: FC<Props> = ({
  isPrivate,
  getContainer,
  canCreatePrivateComments,
  handleFocus,
  mentionRef,
  uiSize,
  hasInvalidMentions,
  currentUserId,
  organizationId,
  billingRights,
  ownerEmail,
  bugherdUrl,
  hideLabel,
  onPlanUpgradeClick,
  handlePrivateToggle,
  showUpgrade,
  setShowUpgrade
}) => {
  const [showInvalidMentions, setShowInvalidMentions] = useState<boolean>(
    false
  );

  const handleSwitch = (checked: boolean) => {
    if (hasInvalidMentions) {
      return;
    }

    if (checked) {
      mentionRef?.current?.focus();
      handleFocus();
    }
    handlePrivateToggle(checked);

    track('Toggle Members only comment', {
      userId: currentUserId,
      organizationId
    });
  };

  const switchSize = uiSize === 'middle' ? 'default' : uiSize;

  return (
    <div
      className={cx(styles.toggleOuter, {
        [styles.isPrivate]: isPrivate,
        [styles.isSmallView]: uiSize === 'small'
      })}
      data-intercom-target="members-only-comments"
    >
      {hideLabel ? (
        <Tooltip
          overlayClassName={styles.tooltip}
          title={
            isPrivate ? strings.toggleForMembersOnly : strings.toggleForAllUsers
          }
          placement="topRight"
          getPopupContainer={getContainer}
        >
          <span
            className={cx(styles.iconOuter, styles.hiddenLabel)}
            onClick={() => handlePrivateToggle(!isPrivate)}
          >
            <Eye className={styles.eyeIcon} />
          </span>
        </Tooltip>
      ) : (
        [
          <span
            key="icon"
            className={styles.iconOuter}
            onClick={() => handlePrivateToggle(!isPrivate)}
          >
            <Eye className={styles.eyeIcon} />
          </span>,
          <span key="label" className={styles.visibleTo}>
            {isPrivate ? strings.membersOnly : strings.allUsers}
          </span>
        ]
      )}
      {!canCreatePrivateComments ? (
        <UpgradePopover
          isSmallView={hideLabel}
          open={!!showUpgrade}
          onOpenChange={visible => setShowUpgrade(visible)}
          message={
            <p className={styles.upgradeMessage}>
              {strings.make}
              <span className={styles.membersOnlyComments}>
                {strings.membersOnlyComments}
              </span>
              {billingRights ? (
                strings.upgradePremium
              ) : (
                <span className={styles.contactOwner}>
                  {strings.pleaseContact}
                  {ownerEmail ? (
                    [
                      strings.yourAccount,
                      <a href={`mailto:${ownerEmail}`}>{strings.owner}</a>
                    ]
                  ) : (
                    <a href="mailto:support@bugherd.com">{strings.support}</a>
                  )}
                </span>
              )}
            </p>
          }
          placement="topRight"
          getContainer={getContainer}
          className={styles.upgradePopover}
          imageSrc={premiumFeatureImage}
          billingRights={billingRights}
          ownerEmail={ownerEmail}
          href={`${bugherdUrl}/organizations/${organizationId}/subscription`}
        >
          <div
            className={styles.switchContainer}
            onClick={() => {
              if (onPlanUpgradeClick) {
                onPlanUpgradeClick();
              }
            }}
          >
            <Switch
              size={switchSize as SwitchSize}
              checked={false}
              className={styles.switch}
              data-intercom-target="members only comment"
            />
            <ArrowUp className={styles.upgradeArrow} />
          </div>
        </UpgradePopover>
      ) : (
        <Popover
          overlayClassName={styles.popover}
          open={showInvalidMentions}
          onOpenChange={visible => {
            if (hasInvalidMentions) setShowInvalidMentions(visible);
          }}
          placement="leftTop"
          trigger="click"
          title={null}
          getPopupContainer={getContainer}
          content={
            <div className={styles.popoverContent}>
              <p>
                <ExclamationCircleFilled className={styles.popoverIcon} />
                <span>{strings.mentionedIn}</span>
              </p>
              <div className={styles.okContainer}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowInvalidMentions(false)}
                >
                  {strings.ok}
                </Button>
              </div>
            </div>
          }
        >
          <Switch
            size={switchSize as SwitchSize}
            checked={isPrivate}
            className={styles.switch}
            onChange={handleSwitch}
            data-intercom-target="members only comment"
          />
        </Popover>
      )}
    </div>
  );
};

export default PrivateToggle;
