import React, { useState, useEffect, FC } from 'react';
import styles from './index.module.css';
import Assets from './Assets';
import { ArchivedFilter } from './ArchivedFilter';
import {
  useDesignAssetsState,
  ASSETS_SUPPORT_ARTICLE_ID
} from '../../../clients/providers/DesignAssets';
import HelpArticleButton from 'appJS/utils/HelpArticleButton';
import { ShareModal } from './ShareModal';
import { GroupNameModal } from './GroupNameModal';
export const UNGROUPED = 'Ungrouped';
import { default as DesignLogo } from './assets/design_icon.svg';
import SearchAndFilter from './SearchAndFilter';

const getShowGroupUrl = async (groupName, projectId) => {
  try {
    const response = await fetch(
      `assets/generate_show_group_url?group_name=${groupName}&project_id=${projectId}`
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error('Failed to fetch show group URL:', error);
    return '';
  }
};

export const AssetsScreen: FC = () => {
  const {
    data,
    container,
    bugherdUrl,
    handleSearchAndSort,
    setShareItem,
    shareItem,
    project,
    isGroupNameModalOpen,
    setIsGroupNameModalOpen,
    setGroupName,
    groupName,
    onAddToGroup
  } = useDesignAssetsState();

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [shareUrl, setShareUrl] = useState<string>('');
  const assets = handleSearchAndSort();
  const numberOfActiveAssets = assets.filter(
    asset => asset.status !== 'archived'
  ).length;

  const fetchShowGroupUrl = async () => {
    const url = await getShowGroupUrl(shareItem, project.id);
    setShareUrl(url);
  };

  useEffect(() => {
    if (shareItem === null) {
      setShareUrl('');
    } else if (typeof shareItem === 'string') {
      fetchShowGroupUrl();
    } else {
      setShareUrl(`${bugherdUrl}/projects/${project.id}/assets/${shareItem}`);
    }
  }, [shareItem]);

  return (
    <>
      <ShareModal
        open={!!shareItem}
        closeModal={() => setShareItem(null)}
        shareURL={shareUrl}
        container={container}
        canInviteToProject
      />
      <GroupNameModal
        onAddToGroup={onAddToGroup}
        setIsGroupNameModalOpen={setIsGroupNameModalOpen}
        setGroupName={setGroupName}
        isGroupNameModalOpen={isGroupNameModalOpen}
        container={container}
        groupName={groupName}
      />

      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>
          <DesignLogo />
          <h2>Designs</h2>
        </div>
        <div className={styles.archiveSearchWrapper}>
          <SearchAndFilter />
          <ArchivedFilter
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            numberOfActiveAssets={numberOfActiveAssets}
          />
          <HelpArticleButton articleId={ASSETS_SUPPORT_ARTICLE_ID} />
        </div>
      </div>

      <Assets assets={assets} data={data} disabled={showArchived} />
    </>
  );
};
