import React, { FC, useState } from 'react';
import { Popover, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../../../clients/sidebar/models/Language';
import styles from './styles.module.css';

type Props = {
  save: () => void;
  container: HTMLDivElement;
  isValidPath: boolean;
};

const strings = translations[getLangKey()];

const PathPopover: FC<Props> = ({ save, container, isValidPath }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Popover
      overlayClassName={styles.popover}
      onOpenChange={visible => {
        if (!isValidPath) {
          setShow(visible);
        }
      }}
      open={show}
      placement="leftTop"
      trigger="click"
      title={null}
      getPopupContainer={() => container}
      content={
        <div className={styles.popoverContent}>
          <p>
            <ExclamationCircleFilled className={styles.popoverIcon} />
            <span>
              {strings.pathBeginWith}
              <code>/</code>.
            </span>
          </p>
          <div className={styles.okContainer}>
            <Button
              size="small"
              type="primary"
              onClick={() => setShow(false)}
              className={styles.popoverOk}
            >
              {strings.okButton}
            </Button>
          </div>
        </div>
      }
    >
      <Button
        className={styles.locationSave}
        type="primary"
        size="small"
        onClick={save}
      >
        {strings.okButton}
      </Button>
    </Popover>
  );
};

export default PathPopover;
