import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import styles from './styles.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';

type Props = {
  owner: {
    email: string;
    name: string;
    id: number;
  } | null;
  assetIdOrGroup: number | string;
  container: HTMLDivElement;
  projectId: number;
  loggedIn: boolean;
  toProjects?: boolean;
};

const strings = translations[getLangKey()];

const AssetBanner: FC<Props> = ({
  owner,
  assetIdOrGroup,
  projectId,
  container,
  loggedIn,
  toProjects
}) => {
  const assetParameter =
    typeof assetIdOrGroup === 'string'
      ? `show_group?group_name=${assetIdOrGroup}`
      : assetIdOrGroup;

  return (
    <Modal
      open
      footer={null}
      title={null}
      closable={false}
      width="90%"
      className={styles.modal}
      getContainer={container}
    >
      <div className={styles.contentInner}>
        <h2 className={styles.header}>
          {loggedIn ? strings.invited : strings.loginHeader}
        </h2>
        {!loggedIn && (
          <Button
            type="primary"
            href={`/users/sign_in?asset_path=/projects/${projectId}/assets/${assetParameter}`}
          >
            {strings.login}
          </Button>
        )}
        <p className={styles.bannerMessage}>
          {loggedIn || !owner ? (
            strings.contactOwner(owner)
          ) : (
            <>
              {strings.noAccount}
              <Button
                type="link"
                href={`mailto:${owner?.email}`}
                className={styles.ownerButton}
              >
                {strings.projectOwner}
              </Button>
            </>
          )}
        </p>
        {toProjects && (
          <a href="/projects">
            <Button type="primary" className={styles.projectsButton}>
              Back to my projects
            </Button>
          </a>
        )}
      </div>
    </Modal>
  );
};

export default AssetBanner;
