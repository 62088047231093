import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ForgotPassword from './forgot_password';
import Login from './login';
import SignupCaptureUrl from './sign_up_capture_url';
import SingleSignOn from './single_sign_on';
import SignUp from './register_sign_up';
import SignUpEmail from './sign_up_email';
import RegisterSso from './register_sso_page';
import ResetPassword from 'views/reset_password';
import { EmailSent } from './login/EmailSent';
import { LoginLink } from './login/LoginLink';
import MobileHeader from './shared/MobileHeader';
import Iframe from './shared/Iframe';

import styles from './index.module.css';
export default (props: any) => {
  return (
    <BrowserRouter basename="/users">
      <div className={styles.signUpExperiment}>
        <MobileHeader />
        <div className={styles.registerWrapper}>
          <div className={styles.formWebsiteWrapper}>
            <div className={styles.formWrapper}>
              <Routes>
                <Route
                  path="/passwords/new"
                  element={<ForgotPassword {...props} />}
                />
                <Route path="/sign_in" element={<Login {...props} />} />

                <Route
                  path="/sign_in/invite_link"
                  element={<Login {...props} expiredInviteLink />}
                />

                <Route
                  path="/sign_in/sso"
                  element={<SingleSignOn {...props} />}
                />
                <Route
                  path="/sign_in/login_link/email_sent"
                  element={<EmailSent />}
                />

                {props.loginLinkEnabled && (
                  <Route path="/sign_in/login_link" element={<LoginLink />} />
                )}

                <Route
                  path="/registrations/try-bugherd"
                  element={<SignupCaptureUrl {...props} />}
                />
                <Route
                  path="/registrations/new"
                  element={<SignUp {...props} />}
                />

                <Route
                  path="/registrations/sign-up-email"
                  element={<SignUpEmail {...props} />}
                />

                <Route
                  path="/registrations/sso_sign_up_form"
                  element={<RegisterSso {...props} />}
                />
                <Route
                  path="/passwords/:id/edit"
                  element={<ResetPassword {...props} />}
                />
              </Routes>
            </div>
          </div>
          <Iframe />
        </div>
      </div>
    </BrowserRouter>
  );
};
