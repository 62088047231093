import React, { FC } from 'react';
import styles from './styles.module.css';
import { AddButton } from '../AddButton';
import { View } from '../../project_nav/types';
import { UploadDropdown } from '../../../../../clients/design_assets/UploadDropdown';
import { Tooltip } from 'antd';
import { useDesignAssetsState } from '../../../../../../clients/providers/DesignAssets';
import { useDispatch } from 'react-redux';

type Props = {
  view: View | null;
};

export const AddTaskOrAssetButton: FC<Props> = ({ view }) => {
  const { viewGuestKanban } = useDesignAssetsState();
  const dispatch = useDispatch();

  if (viewGuestKanban) return null;

  return (
    <Tooltip
      placement="left"
      title={view === View.Assets ? 'Add an asset' : null}
    >
      <span>
        {view === 'assets' ? (
          <UploadDropdown />
        ) : (
          view !== View.Home && (
            <AddButton
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                dispatch({
                  type: 'CREATE_TASK'
                });
                //eslint-disable-next-line no-unused-expressions
              }}
              className={styles.addButton}
              btnText={'Add Task'}
            />
          )
        )}
      </span>
    </Tooltip>
  );
};
