import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode
} from 'react';
import Bugsnag from '@bugsnag/js';
import { get } from '../../redesign/javascript/utils/fetch';
import camelCaseKeys from 'camelcase-keys';
import { CurrentUser } from '../sidebar/models/CurrentUser';
import { emit } from '../embed/utils/extensionMessenger';
import log from '../embed/utils/log';

// @ts-ignore
const CurrentUserStateContext = createContext();

const CurrentUserProvider = ({
  apiKey,
  apiDomain,
  children,
  projectId
}: {
  apiKey?: string;
  apiDomain: string;
  children: ReactNode;
  projectId?: number;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);
  const [extensionExists, setExtensionExists] = useState<boolean>(false);

  const checkExtensionExists = () =>
    new Promise((resolve, reject) => {
      emit(
        {
          eventName: 'bugherdExistanceRequest',
          responseEventName: 'bugherdExistanceResponse'
        },
        () => {
          resolve(true);
        }
      );

      setTimeout(() => {
        resolve(false);
      }, 100);
    });

  const updateCurrentUser = (attributes: any) =>
    setCurrentUser(Object.assign({}, currentUser, attributes));

  const identifyUserForVendors = (user: CurrentUser) => {
    Bugsnag.setUser(user.id.toString());
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (apiKey) searchParams.append('apikey', apiKey);
    else if (projectId) searchParams.append('project_id', projectId.toString());

    const baseURL = apiDomain || `https://${window.location.host}`;
    const currentUserEndpoint = new URL(`${baseURL}/users/current`);
    currentUserEndpoint.search = searchParams.toString();

    get(currentUserEndpoint.href)
      .then(data => {
        const camelUser = camelCaseKeys(data, { deep: true });
        log({ data });
        setCurrentUser(camelUser);

        identifyUserForVendors(camelUser);

        // @ts-ignore
        checkExtensionExists().then((exists: boolean) => {
          setExtensionExists(exists);
          setLoading(false);
        });
      })
      .catch(err => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    log({ currentUser });
  }, [currentUser]);

  return (
    <CurrentUserStateContext.Provider
      value={{
        loading,
        currentUser,
        extensionExists,
        updateCurrentUser,
        apiDomain
      }}
    >
      {children}
    </CurrentUserStateContext.Provider>
  );
};

type CurrentUserState = {
  loading: boolean;
  currentUser: CurrentUser;
  extensionExists: boolean;
  updateCurrentUser: (attributes: any) => void;
  apiDomain: string;
};

const useCurrentUserState = (): CurrentUserState => {
  const context = useContext(CurrentUserStateContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentUserState must be used within a CurrentUserProvider'
    );
  }

  return context;
};

export { CurrentUserProvider, useCurrentUserState, CurrentUserState };
