import React, { useState, useEffect, FC } from 'react';
import styles from '../index.module.css';
import { Dropdown, Menu, message, Button } from 'antd';
import { MoreHorizontal, Edit, Trash, Share, Camera } from 'lucide-react';
import cx from 'classnames';
import { deleteProjectSites } from './ProjectSites';
import { useDesignAssetsState } from '../../../../clients/providers/DesignAssets';
import { useProjectSitesState } from '../../../../clients/providers/ProjectSites';

type Props = {
  container: HTMLDivElement;
  disabled: boolean;
  id: string;
  apiKey: string;
  setWebsites?: (value: any) => void;
  projectId: string;
  handleOpenWebsiteModal: () => void;
  handleGetCoverImage: (id: string) => void;
};

const WebsiteMenu: FC<Props> = ({
  container,
  disabled,
  id,
  apiKey,
  projectId,
  handleOpenWebsiteModal,
  handleGetCoverImage
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const DELETE = 'delete';

  useEffect(() => {
    if (confirmDelete) {
      setIsMenuOpen(true);
    }
  }, [confirmDelete]);

  const {
    isNoLoginGuestExperienceEnabled,
    onSharingClick
  } = useDesignAssetsState();

  const { setProjectSites, handleGetProjectSites } = useProjectSitesState();

  const handleDeleteWebsite = async () => {
    if (confirmDelete) {
      const response = await deleteProjectSites(apiKey, id, projectId);
      if (response) {
        message.info('Your website url has been deleted.');
        setConfirmDelete(false);
        setProjectSites(previousValue =>
          previousValue.filter(site => site.id.toString() !== id)
        );
        handleGetProjectSites();
      } else if (response === 403) {
        message.error('You do not have permission to delete this website');
      } else {
        //eslint-disable-next-line
        window?.bugsnagClient
          ? window.bugsnagClient.notify(response)
          : console.error(response);
        message.error('Something went wrong. Please try again');
      }
    } else {
      setConfirmDelete(true);
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    setIsMenuOpen(visible);
  };

  const menuItems = [
    {
      key: 'share',
      show: isNoLoginGuestExperienceEnabled,
      icon: <Share className={styles.shareIcon} />,
      text: 'Share',
      onClick: () => onSharingClick({ siteId: id })
    },
    {
      key: 'rename',
      show: !disabled,
      icon: <Edit className={styles.editIcon} />,
      text: 'Edit website',
      onClick: () => handleOpenWebsiteModal()
    },
    {
      key: 'image',
      show: true,
      icon: <Camera className={styles.editIcon} />,
      text: 'Get cover image',
      onClick: () => handleGetCoverImage(id)
    },
    {
      key: DELETE,
      show: true,
      icon: <Trash className={styles.trashIcon} />,
      text: confirmDelete ? 'Really Delete?' : 'Delete',
      onClick: handleDeleteWebsite
    }
  ];

  return (
    <>
      <Dropdown
        className={styles.assetDropdown}
        trigger={['click']}
        getPopupContainer={() => container}
        onOpenChange={handleVisibleChange}
        open={isMenuOpen}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName={styles.overlay}
        overlay={
          <Menu className={styles.menu}>
            {menuItems
              .filter(({ show }) => show)
              .map(({ key, title, type, items, icon, text, onClick }) =>
                type === 'submenu' ? (
                  <Menu.SubMenu
                    key={key}
                    title={title}
                    className={styles.menuInner}
                    icon={icon}
                  >
                    {items}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={key}
                    className={styles.menuItem}
                    onClick={onClick}
                  >
                    <div className={styles.menuInner}>
                      {icon}
                      {text}
                    </div>
                  </Menu.Item>
                )
              )}
          </Menu>
        }
      >
        <Button
          shape="circle"
          onClick={event => event.preventDefault()}
          type="default"
        >
          <MoreHorizontal
            onClick={event => event.preventDefault()}
            className={cx(styles.moreIcon, { [styles.active]: isMenuOpen })}
          />
        </Button>
      </Dropdown>
    </>
  );
};

export default WebsiteMenu;
