import React, { FC } from 'react';
import styles from './index.module.css';
import { DesignAsset } from './types';
import { GroupedAssets } from './GroupedAssets';
import { UNGROUPED } from './AssetsScreen';
import { useDesignAssetsState } from '../../../clients/providers/DesignAssets';

type AssetsProps = {
  assets: DesignAsset[];
  data: DesignAsset[];
  disabled: boolean;
};

const Assets: FC<AssetsProps> = ({ assets, data, disabled }) => {
  const status = disabled ? 'archived' : 'created';

  // really weird way to get unique array of folder names
  const groups = data
    .filter(asset => asset.status === status)
    .reduce((result: string[], currentValue: DesignAsset) => {
      if (currentValue.groupName && !result.includes(currentValue.groupName)) {
        result.unshift(currentValue.groupName);
      }
      return result;
    }, []);

  const groupedAssets = groups.map(group => ({
    name: group,
    assets: assets.filter(
      asset => asset.groupName === group && asset.status === status
    )
  }));

  const ungroupedAssets = {
    name: UNGROUPED,
    assets: assets.filter(asset => !asset.groupName && asset.status === status)
  };

  const { viewGuestKanban } = useDesignAssetsState();

  return (
    <div className={styles.cardsContainer}>
      {groupedAssets.map(({ name, assets }) => {
        return (
          <GroupedAssets
            key={name}
            name={name}
            assets={assets}
            groups={groups}
            disabled={disabled}
            showDropdown={!viewGuestKanban}
          />
        );
      })}
      <>
        <GroupedAssets
          name={ungroupedAssets.name}
          assets={ungroupedAssets.assets}
          groups={groups}
          disabled={disabled}
          key={'ungrouped'}
          showDropdown={false}
        />
      </>
    </div>
  );
};

export default Assets;
