type Logger = typeof console.log;

const LOWERCASE_BUGHERD_DEBUG_QUERY_PARAM = 'bugherddebug';

const mockLogger = (...args: any) => {};
const createLogger = (): Logger => {
  // Ensure that we are being case-insensitive when checking query params
  const params = new URLSearchParams(window.location.search).keys();
  const debugEnabledInQueryParams = Array.from(params)
    .map(param => LOWERCASE_BUGHERD_DEBUG_QUERY_PARAM === param.toLowerCase())
    .some(x => x);

  // @ts-expect-error
  const debugEnabledInConfig = window.BugHerdConfig?.debug === 'true';

  const debugLoggingEnabled = debugEnabledInQueryParams || debugEnabledInConfig;

  if (debugLoggingEnabled) {
    console.log('[BUGHERD] BugHerd debug logging is enabled');
    return (...args) => console.log('[BUGHERD]', ...args);
  }
  return mockLogger;
};

export default createLogger();
