import React, { FC, useState } from 'react';
import { Button, message, Modal } from 'antd';
import styles from './index.module.css';
import { X as Close } from 'lucide-react';
import MarketingImage from './assets/feedback-on-designs.png';
import { useDesignAssetsState } from '../../../clients/providers/DesignAssets';
import { get } from 'appJS/utils/fetch';

export const PlaceHolder: FC = () => {
  const {
    isModalOpen,
    setIsModalOpen,
    project,
    container
  } = useDesignAssetsState();

  const handleClose = () => setIsModalOpen(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { organizationId } = project;

  if (!Number.isSafeInteger(organizationId)) {
    throw new Error('Problem when serialising the organization id');
  }

  const onClick = async () => {
    setLoading(true);
    try {
      await get(
        `/settings/organizations/${organizationId}/experiments/enable?experiment_name=design_asset_feedback`
      );
      window.location.replace(`/projects/${project.id}/assets`);
    } catch (e) {
      message.info('There was an error enabling your user for the BETA');
      handleClose();
    }
  };

  if (!container) throw new Error('No container found to mount modal to');

  return (
    <Modal
      open={isModalOpen}
      className={styles.placeholderModal}
      getContainer={container}
      closable={false}
      destroyOnClose
      onCancel={handleClose}
      footer={null}
    >
      <h1>Feedback on Deliverables with BugHerd!</h1>
      <div className={styles.closeContainer} onClick={handleClose}>
        <Close className={styles.closeIcon} />
      </div>
      <div className={styles.modalContent}>
        <p>
          You can now use BugHerd to capture feedback on your Figma files, PDFs
          or images. To try it out <strong>get started</strong> below, or&nbsp;
          <a
            href="https://support.bugherd.com/en/articles/84911-feedback-on-figma-images-and-pdfs-beta"
            data-elevio-article={84911}
          >
            <strong>find out more here.</strong>
          </a>
        </p>
        <p>
          We’d love to hear your feedback, let us know via our in-app support
          contact form.
        </p>
      </div>
      <Button
        type="primary"
        shape="round"
        size="large"
        loading={loading}
        onClick={onClick}
      >
        Get Started
      </Button>
      <div className={styles.contentContainer}>
        <img src={MarketingImage} className={styles.marketingImage} />
      </div>
    </Modal>
  );
};
