import React, { FC, MouseEventHandler, MouseEvent } from 'react';
import styles from './index.module.css';
import { ShareModalProps } from './types';
import { Button, Modal, Space, Input, Skeleton } from 'antd';
import { CloseModal } from 'appJS/components/CloseModal';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import { handleCopy } from 'appJS/utils/handleCopy';

const strings = translations[getLangKey()];

export const ShareModal: FC<ShareModalProps> = ({
  shareURL,
  container,
  open,
  closeModal,
  canInviteToProject,
  inviteEndpoint
}) => {
  const isShareGroup = shareURL?.includes('share_group');
  const handleInviteButtonClick: MouseEventHandler<HTMLElement> = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (inviteEndpoint) {
      window.open(inviteEndpoint, '_self');
    } else {
      closeModal();
      //@ts-expect-error
      window.share_project_props?.setShow?.(true);
    }
  };

  return (
    <Modal
      wrapClassName={styles.shareModal}
      open={open}
      getContainer={container}
      closeIcon={
        <CloseModal className={styles.closeModal} handleClick={closeModal} />
      }
      onCancel={closeModal}
      title={null}
      footer={null}
      centered
    >
      <Skeleton
        loading={!shareURL}
        paragraph={{ rows: 3, width: ['100%', '100%', '70%'] }}
        active
      >
        <h2 className={styles.shareModalHeader}>
          {isShareGroup ? strings.shareGroupHeader : strings.shareHeader}
        </h2>
        <p className={styles.shareUrlMessage}>{strings.shareUrlMessage}</p>
        <Space.Compact className={styles.inputGroup}>
          <Input className={styles.assetUrlInput} value={shareURL} readOnly />
          <Button
            className={styles.copyButton}
            onClick={() =>
              handleCopy({
                text: shareURL,
                successMessage:
                  'The asset URL has been copied to your clipboard.'
              })
            }
            type="primary"
          >
            {strings.copy}
          </Button>
        </Space.Compact>
        {canInviteToProject && (
          <Button
            type="link"
            href={inviteEndpoint}
            className={styles.inviteLink}
            onClick={handleInviteButtonClick}
          >
            {strings.inviteMessage}
          </Button>
        )}
      </Skeleton>
    </Modal>
  );
};
