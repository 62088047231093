import React, { FC, ReactNode } from 'react';
import { Upload } from 'antd';
import { useDesignAssetsState } from '../../../clients/providers/DesignAssets';

type Props = {
  children: ReactNode;
};

export const AssetsUpload: FC<Props> = ({ children }) => {
  const { onUploadChange } = useDesignAssetsState();

  return (
    <Upload
      name="file"
      onChange={onUploadChange}
      showUploadList={false}
      multiple
      customRequest={() => {}}
      accept="image/*, .pdf"
    >
      {children}
    </Upload>
  );
};
