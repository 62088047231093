import React, {
  FC,
  useState,
  useCallback,
  useReducer,
  useMemo,
  useEffect
} from 'react';

import * as Types from './types';
import { X as Close, Info } from 'lucide-react';
import useMediaQuery from './useMediaQuery';

import { UploadFile } from 'antd/lib/upload/interface';
import Overlay from './overlay';
import Dragger from './dragger';

import Draggable from 'react-draggable';
import cx from 'classnames';

import styles from './index.module.css';
import RightPanel from './right_panel';
import LeftPanel from './left_panel';
import useHideParentOverflow from '../../hooks/useHideParentOverflow';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import usePreferences from './usePreferences';
import useConfigDefaultsInCreateTask from '../../hooks/useConfigDefaults';
import { AntdWrapper } from 'appClients/shared/AntdWrapper';
import { TourStep } from '../../utils/onboardingTour';
import { post } from '../../../../../redesign/javascript/utils/fetch';
import { getSortedAssignableUsers } from '../../../../../redesign/javascript/utils/getAssignableUsers';
import { getGlobalPreferences } from '../../utils/getPreferences';
import { FileType } from '../../../../../redesign/javascript/utils/fileListOperations';

const strings = translations[getLangKey()];

const getTaskAttributes = (taskState: Types.TaskState): Types.Attributes => {
  const NOT_SET_ID = 0;
  const {
    description,
    assignees,
    fileList,
    status,
    severity,
    tags,
    dataURI,
    annotated,
    requesterName,
    requesterEmail
  } = taskState;
  return {
    description,
    assignee_ids: assignees,
    attachments: fileList.map(({ name, url }) => {
      return { name, url };
    }),
    column_id: status,
    priority_id: severity !== undefined ? severity : NOT_SET_ID,
    tag_ids: tags.map((tag: Types.Tag) => (tag.id >= 1 ? tag.id : null)),
    tag_names: tags.map((tag: Types.Tag) => tag.display),
    dataURI,
    annotated_screenshot: annotated,
    requester_email: requesterEmail,
    requester_name: requesterName
  };
};

const taskReducer: React.Reducer<Types.TaskState, Types.TaskStateActions> = (
  state: Types.TaskState,
  action: Types.TaskStateActions
) => {
  switch (action.type) {
    case 'SetDescription':
      return { ...state, description: action.description };
    case 'SubmitTask':
      action.create(getTaskAttributes(state));
      return state;
    case 'SetDataURI':
      return { ...state, dataURI: action.blob };
    case 'SetAssignees':
      return { ...state, assignees: action.assigneeIds };
    case 'SetSeverity':
      return { ...state, severity: action.priorityId };
    case 'SetStatus':
      return { ...state, status: action.columnId };
    case 'SetTags':
      return { ...state, tags: action.tags };
    case 'SetFileList':
      return { ...state, fileList: action.fileList };
    case 'SetRequesterName':
      return { ...state, requesterName: action.requesterName };
    case 'SetRequesterEmail':
      return { ...state, requesterEmail: action.requesterEmail };
    default:
      throw new Error();
  }
};

const CreateTask: FC<Types.CreateTask> = props => {
  const {
    isAdminView,
    attachmentsUrl,
    container,
    extensionsUrl,
    subscriptionUrl,
    task,
    accessTo,
    close,
    modalOuter,
    columns,
    availableTags,
    create,
    firstColumn,
    dataURI,
    columnId,
    projectName,
    assignableUsers: _assignableUsers,
    fluidHeight,
    useParentWindow,
    owner,
    projectId,
    createTagsViaNewTask,
    setTourStep,
    createTaskModalRef,
    createTaskButtonRef,
    isLoginlessFeedback,
    updateCurrentUser,
    user,
    apiDomain
  } = props;

  const {
    canEditAssignees,
    canEditStatus,
    canEditSeverity,
    billing,
    canEditTags,
    assignGuests,
    assignGuestsAllowed
  } = accessTo;

  const isGuest = user?.role === 'guest';
  // for guests we want the the task to go to feedback column
  const firstColumnStatus = isGuest ? columns[0]?.id : columnId || firstColumn;

  const [firstName, setFirstName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');

  useEffect(() => {
    const getUserPreferences = async () => {
      try {
        const getEmailAddress = await getGlobalPreferences('emailAddress');
        if (typeof getEmailAddress === 'string') {
          setEmailAddress(getEmailAddress);
        }

        const getFirstName = await getGlobalPreferences('firstName');
        if (typeof getFirstName === 'string') {
          setFirstName(getFirstName);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    getUserPreferences();
  }, []);

  const [taskState, dispatch] = useReducer(taskReducer, {
    description: '',
    assignees: [],
    status: firstColumnStatus,
    severity: undefined,
    tags: [],
    fileList: [],
    annotated: false,
    dataURI: dataURI,
    requesterName: firstName || '',
    requesterEmail: emailAddress || ''
  });

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [overlay, setOverlay] = useState<Types.Overlay>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const isSmallView = useMediaQuery('(max-width: 600px)', useParentWindow);
  const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const isMobileView = useMediaQuery('(max-width: 475px)', useParentWindow);
  const [isReturningUser, setIsReturningUser] = useState<boolean>(false);
  const [pastedFile, setPastedFile] = useState<FileType | null>(null);

  useHideParentOverflow();

  const assignableUsers = getSortedAssignableUsers(
    _assignableUsers || [],
    assignGuestsAllowed && assignGuests
  );

  const {
    handleCheckboxChange,
    storePreference,
    rememberTaskSettings
  } = usePreferences({
    projectId,
    availableTags,
    assignableUsers,
    isAdminView,
    dispatch,
    taskState,
    columns
  });

  const userHasNoFirstName = !(user.firstName || user.first_name);

  const onEmailChange = (requesterEmail: string) => {
    dispatch({ type: 'SetRequesterEmail', requesterEmail });
  };

  const initUserEmail = () => onEmailChange(user.email);

  useEffect(() => {
    if (userHasNoFirstName) initUserEmail();
    if (isMobileView || !setTourStep || emailAddress) return;
    setTourStep(TourStep.CREATE_TASK_MODAL_OPENED);
  }, []);

  useEffect(() => {
    if (isMobileView || !setTourStep || emailAddress) return;
    const tourStep = submitDisabled
      ? TourStep.CREATE_TASK_MODAL_OPENED
      : TourStep.DESCRIPTION_ADDED;
    setTourStep(tourStep);
  }, [submitDisabled]);

  useEffect(() => {
    if (createTaskModalRef) {
      createTaskModalRef.current = modalRef;
    }
  }, [modalRef]);

  useEffect(() => {
    if (!fluidHeight) {
      const actualWindow = isAdminView ? window : window.parent;
      if (actualWindow.visualViewport) {
        setContainerHeight(actualWindow.visualViewport.height);
      } else {
        setContainerHeight(window.parent.innerHeight);
      }
    }
  }, [isAdminView]);

  useEffect(() => {
    const listenForEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    window.addEventListener('keydown', listenForEscape);

    return () => {
      window.removeEventListener('keydown', listenForEscape);
    };
  });

  const removeFile = useCallback(
    (file: UploadFile<any>) => {
      const updatedFileList = taskState.fileList.filter(
        _file => _file.uid !== file.uid
      );
      dispatch({ type: 'SetFileList', fileList: updatedFileList });
    },
    [taskState.fileList]
  );

  const onDescriptionChange = useCallback((description: string) => {
    dispatch({ type: 'SetDescription', description });
  }, []);

  const onAssigneesChange = useCallback(
    (assigneeIds: number[]) => {
      storePreference('assigneeIds', assigneeIds);
      dispatch({ type: 'SetAssignees', assigneeIds });
    },
    [rememberTaskSettings]
  );

  const onFileListChange = useCallback((fileList: any[]) => {
    dispatch({ type: 'SetFileList', fileList });
  }, []);

  const onSeverityChange = useCallback(
    (priorityId: Types.Severity | undefined) => {
      storePreference('priorityId', priorityId);
      dispatch({ type: 'SetSeverity', priorityId });
    },
    [rememberTaskSettings]
  );

  const onStatusChange = useCallback(
    (columnId: number) => {
      storePreference('columnId', columnId);
      dispatch({ type: 'SetStatus', columnId });
    },
    [rememberTaskSettings]
  );

  const onTagsChange = useCallback(
    (tags: Types.Tag[]) => {
      storePreference('tags', tags);
      dispatch({ type: 'SetTags', tags });
    },
    [rememberTaskSettings]
  );

  const onAnnotationChange = useCallback((blob: Blob) => {
    dispatch({ type: 'SetDataURI', blob });
  }, []);

  const onProgress = useCallback(
    (loaded: number, total: number, lengthComputable: boolean) => {
      if (lengthComputable) {
        setUploadProgress(Math.round((loaded / total) * 100));
      }
    },
    []
  );

  const onSuccess = useCallback(() => {
    setOverlay('success');
    close({ resetTour: false });
    setUploadProgress(0);
    setTourStep(TourStep.TASK_SUBMITTED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close]);

  const onError = useCallback((errorMessage: string) => {
    if (errorMessage === 'account in use') {
      setOverlay('accountInUse');
    } else {
      setOverlay('error');
    }
  }, []);

  const onUploading = useCallback(() => {
    setOverlay('uploading');
  }, []);

  const onLoading = useCallback(() => {
    setOverlay('loading');
  }, []);

  const onShortcuts = useCallback(() => {
    setOverlay('shortcuts');
  }, []);

  const onReturningUserChange = useCallback(() => {
    setIsReturningUser(true);
  }, []);

  useConfigDefaultsInCreateTask({
    availableTags,
    onTagsChange,
    assignableUsers,
    onAssigneesChange,
    onSeverityChange
  });

  const onNameChange = (requesterName: string) => {
    dispatch({ type: 'SetRequesterName', requesterName });
  };

  const [emailStatus, setEmailStatus] = useState<
    undefined | 'warning' | 'error'
  >();
  const [nameStatus, setNameStatus] = useState<
    undefined | 'warning' | 'error'
  >();

  const { requesterEmail, requesterName } = taskState;

  useEffect(() => {
    const standardSubmitDisabled =
      taskState.description.trim().length < 1 || isUploading;
    const loginlessFeedbackDisabled =
      isLoginlessFeedback && (!requesterName || !requesterEmail);

    setSubmitDisabled(
      (standardSubmitDisabled || loginlessFeedbackDisabled) &&
        !(emailAddress && firstName)
    );
  }, [taskState, isUploading]);

  const handleUpdateUserName = () => {
    if (
      requesterEmail === user.email &&
      userHasNoFirstName &&
      requesterName &&
      updateCurrentUser &&
      !emailAddress
    ) {
      post('/users/name', {
        name: requesterName,
        id: user.id
      })
        .then(response => {
          if (response.status === 'ok') {
            updateCurrentUser({ firstName: requesterName });
            onEmailChange('');
            onNameChange('');
            return 'updated';
          }
        })
        .catch(error => console.log(error));
    }
  };

  const submitTask = useCallback(() => {
    if (
      !(emailAddress && firstName) &&
      isLoginlessFeedback &&
      (!requesterName || !requesterEmail)
    ) {
      if (!requesterName) {
        setNameStatus('error');
        setSubmitDisabled(true);
      }
      if (!requesterEmail) {
        setEmailStatus('error');
        setSubmitDisabled(true);
      }
      return;
    }

    const callCreate = (taskAttributes: Types.Attributes) => {
      create(
        { ...taskAttributes, returningUser: isReturningUser },
        onSuccess,
        onLoading,
        onError,
        onUploading,
        onProgress
      );
    };
    dispatch({ type: 'SubmitTask', create: callCreate });
  }, [
    create,
    onSuccess,
    onLoading,
    onError,
    onUploading,
    onProgress,
    taskState,
    submitDisabled
  ]);

  const propsSetUploadingState = props.setUploadingState;
  const setUploadingState = useCallback(
    (uploadingState: boolean) => {
      propsSetUploadingState(uploadingState);
      setIsUploading(uploadingState);
    },
    [propsSetUploadingState]
  );

  const isMemberView: boolean =
    canEditAssignees && canEditSeverity && canEditStatus && canEditTags;

  const onDragStart = useCallback(() => setIsDragging(true), []);
  const onDragStop = useCallback(() => setIsDragging(false), []);
  const outerStyle: { overflow: 'auto'; height?: number | string } = {
    overflow: 'auto',
    height: containerHeight || (isSmallView && '100%') || '100vh'
  };

  const {
    assignees,
    severity,
    tags,
    description,
    fileList,
    status
  } = taskState;

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const clipboardItems = event.clipboardData?.items;

    if (!clipboardItems || clipboardItems.length === 0) {
      return;
    }

    const filteredFile = clipboardItems[0].getAsFile();

    if (filteredFile) {
      setPastedFile(filteredFile);
    }
  };

  return (
    <div
      onPaste={handlePaste}
      className={styles.createTaskOuter}
      style={outerStyle}
    >
      <Draggable
        onStart={onDragStart}
        onStop={onDragStop}
        handle=".dragWrapper"
      >
        <div
          className={cx(styles.createTaskInner, {
            [styles.guestView]: !isMemberView
          })}
          ref={ref => setModalRef(ref)}
        >
          <Dragger {...{ isDragging, container, projectName }} />
          <div className={styles.closeContainer} onClick={close}>
            <Close className={styles.closeIcon} />
          </div>
          <Overlay
            {...{
              overlay,
              setOverlay,
              subscriptionUrl,
              extensionsUrl,
              isAdminView,
              billingRights: billing,
              ownerEmail: owner?.email,
              uploadProgress,
              closeModal: close
            }}
          />
          {isMemberView && overlay === null && (
            <div className={styles.shortcutsContainer} onClick={onShortcuts}>
              <Info className={styles.infoIcon} />
              {strings.keyboardShortcuts}
            </div>
          )}
          {modalRef && (
            <LeftPanel
              isAdminView={isAdminView}
              setUploadingState={setUploadingState}
              container={container}
              task={task}
              accessTo={accessTo}
              modalContainer={modalRef}
              modalOuter={modalOuter}
              attachmentsUrl={attachmentsUrl}
              removeFile={removeFile}
              fileList={fileList}
              onFileListChange={onFileListChange}
              assignees={assignees}
              onAssigneesChange={onAssigneesChange}
              description={description}
              onDescriptionChange={onDescriptionChange}
              tags={tags}
              onTagsChange={onTagsChange}
              availableTags={availableTags}
              severity={severity}
              onSeverityChange={onSeverityChange}
              status={status}
              onStatusChange={onStatusChange}
              columns={columns}
              submitTask={submitTask}
              setOverlay={setOverlay}
              onAnnotationChange={onAnnotationChange}
              dataURI={taskState.dataURI}
              assignableUsers={assignableUsers}
              subscriptionUrl={subscriptionUrl}
              owner={{
                email: owner?.email,
                id: owner?.id
              }}
              createTagsViaNewTask={createTagsViaNewTask}
              handleUpdateUserName={handleUpdateUserName}
              apiDomain={apiDomain}
              projectId={projectId}
              pastedFile={pastedFile}
            />
          )}
          {useMemo(
            () => (
              <RightPanel
                accessTo={accessTo}
                container={container}
                fileList={fileList}
                removeFile={removeFile}
                assignees={assignees}
                onAssigneesChange={onAssigneesChange}
                severity={severity}
                onSeverityChange={onSeverityChange}
                status={status}
                columns={columns}
                onStatusChange={onStatusChange}
                tags={tags}
                onTagsChange={onTagsChange}
                availableTags={availableTags}
                submitTask={submitTask}
                submitDisabled={submitDisabled}
                overlay={overlay}
                isSmallView={isSmallView}
                assignableUsers={assignableUsers}
                rememberTaskSettings={rememberTaskSettings}
                handleCheckboxChange={handleCheckboxChange}
                createTagsViaNewTask={createTagsViaNewTask}
                setTourStep={setTourStep}
                createTaskButtonRef={createTaskButtonRef}
                onEmailChange={onEmailChange}
                onNameChange={onNameChange}
                emailStatus={emailStatus}
                nameStatus={nameStatus}
                isLoginlessFeedback={isLoginlessFeedback}
                userHasNoFirstName={userHasNoFirstName}
                user={user}
                handleUpdateUserName={handleUpdateUserName}
                apiDomain={apiDomain}
                isAdminView={isAdminView}
                projectId={projectId}
                onReturningUserChange={onReturningUserChange}
              />
            ),
            [
              accessTo,
              container,
              fileList,
              removeFile,
              assignees,
              onAssigneesChange,
              severity,
              onSeverityChange,
              status,
              columns,
              onStatusChange,
              tags,
              onTagsChange,
              availableTags,
              submitTask,
              submitDisabled,
              overlay,
              isSmallView,
              assignableUsers,
              rememberTaskSettings,
              handleCheckboxChange,
              createTagsViaNewTask
            ]
          )}
        </div>
      </Draggable>
    </div>
  );
};

export default (props: Types.CreateTask) => (
  <AntdWrapper>
    <CreateTask {...props} />
  </AntdWrapper>
);
