import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useCallback,
  ChangeEvent
} from 'react';
import {
  FigmaUploadProviderProps,
  FigmaNode,
  ToUpdate
} from '../../redesign/clients/design_assets/types';
import _debounce from 'lodash/debounce';
import { useDesignAssetsState } from './DesignAssets';
import { message } from 'antd';
import * as translations from '../../redesign/clients/design_assets/strings';
import { getLangKey } from 'appJS/models/Application';
import { getFileInfo } from 'appJS/utils/fileListOperations';

const strings = translations[getLangKey()];

// @ts-ignore
//eslint-disable-next-line
const FigmaUploadStateContext: React.Context<FigmaUploadState> = createContext();

const DEBOUNCE_WAIT = 1000;
const ORIGINAL_SCALE = 1;
const DEFAULT_PREVIEW_SCALE: number = 0.35;
export const MINIMUM_PREVIEW_SIZE: number = 20000;
const FIGMA_URL_PATTERN: RegExp = /^https:\/\/www\.figma\.com\/(file|proto|design|board)\/[a-zA-Z0-9_-]+\//;
const MAX_REQUESTS_PER_SECOND = 35;
const DELAY_BETWEEN_REQUESTS = (60 * 1000) / MAX_REQUESTS_PER_SECOND;

const FigmaUploadProvider = ({ children }: FigmaUploadProviderProps) => {
  const {
    setIsFigmaModalOpen,
    project,
    uploadAsset,
    getFigmaData,
    reAuthFigma
  } = useDesignAssetsState();
  const [nodes, setNodes] = useState<FigmaNode[]>([]);
  //eslint-disable-next-line
  const [nodeID, setNodeID] = useState<string>('');
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loadingNodes, setLoadingNodes] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [fileKey, setFileKey] = useState<string>('');
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [isImagesLoading, setIsImagesLoading] = useState<boolean>(false);

  const extractFigmaFileId = (url: string) => {
    const figmaURLRegex = /www\.figma\.com\/(file|proto|design|board)\/([^/]+)/;
    const matchFigmaURL = url.match(figmaURLRegex);
    return matchFigmaURL ? matchFigmaURL[2] : undefined;
  };

  const debounceInputOnChange = useCallback(
    _debounce(
      (value: string) => {
        setFileKey(extractFigmaFileId(value) || '');
        setInputValue(value);
      },
      DEBOUNCE_WAIT,
      {
        trailing: true
      }
    ),
    []
  );

  const handleSelect = (isSelected: boolean) => {
    if (nodes?.length)
      setNodes(
        nodes.map(node => ({
          ...node,
          isSelected
        }))
      );
  };

  const selectedNodes = nodes.filter(({ isSelected }) => isSelected);

  const progress: number =
    Math.round(
      selectedNodes.reduce(
        (accumulator, { progress }) => accumulator + (progress || 0),
        0
      ) / selectedNodes.length
    ) || 0;

  const uploadImages = () => {
    setIsImagesLoading(true);

    selectedNodes.forEach(async image => {
      const unscaledImage = await getFigmaData(
        `/images/${fileKey}?ids=${image.id}&format=jpg`
      );

      const imageData = await unscaledImage.json();
      const imageUrl = imageData.images[image.id];
      const imageRequest = await fetch(imageUrl);
      const imageBlob = await imageRequest?.blob();

      const blobFile = await new File([imageBlob], `${image?.name}.png`, {
        type: 'image/png'
      });

      await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_REQUESTS));
      // @ts-expect-error
      const _file = getFileInfo(blobFile);

      if (blobFile) {
        const uploadParameters = {
          file: _file,
          remove: () => {},
          endpoint: `/projects/${project.id}/assets/new`,
          additionalQueryParams: {
            file_name: `${image.name}.png`,
            is_figma: true
          },
          feedback: message.error,
          onStart: () => {},
          onProgress: ({ loaded, total, lengthComputable }: ProgressEvent) => {
            if (lengthComputable)
              //eslint-disable-next-line
              updateNode(image.id, {
                progress: Math.round((loaded / total) * 100)
              });
          },
          onError: () => {},
          onComplete: () => {}
        };
        uploadAsset(uploadParameters);
      }
    });
  };

  const handleError = (response?: any, error?: any) => {
    setHasError(true);
    message.error(strings.figmaUploadError);
    console.error(response ? response.statusText : error);
  };

  const handleNodesResponse = async (_nodeID: string, response?: any) => {
    if (hasError) setHasError(false);
    const nodesData = await response.json();
    const { id, name, children, type } = nodesData.nodes[_nodeID].document;
    const isSelected: boolean = false;
    const _nodes = children.map(({ id, type, name }) => ({
      id,
      type,
      name,
      isSelected
    }));
    _nodes.unshift({ id, name, type, isSelected });
    setNodes(_nodes);
  };

  const getNodesFromLink = async () => {
    let url;

    try {
      url = new URL(inputValue);
    } catch (error) {
      console.error(error);
      message.error(strings.invalidUrl);
      return;
    }

    if (url && FIGMA_URL_PATTERN.test(inputValue)) {
      let response;

      const pageId = url.searchParams.get('page-id');
      const nodeId = url.searchParams.get('node-id');
      let _nodeID = '';

      if (pageId || nodeId) {
        _nodeID = (pageId || nodeId).replace('-', ':');
      }

      if (!_nodeID) return message.error(strings.invalidUrl);

      setNodeID(_nodeID);

      try {
        setLoadingNodes(true);
        const nodesParams = `/files/${extractFigmaFileId(
          inputValue
        )}/nodes?ids=${_nodeID}&depth=1`;
        response = await getFigmaData(nodesParams);
        if (response.status === 200) {
          handleNodesResponse(_nodeID, response);
        } else if (response.status === 404) {
          reAuthFigma();
        } else {
          handleError(response);
        }
      } catch (error) {
        handleError(response, error);
      } finally {
        setLoadingNodes(false);
      }
    } else {
      setHasError(true);
      setFileKey('');
      if (inputValue) message.error(strings.figmaUploadError);
    }
  };

  const getNodeImageURLs = async () => {
    setShowUpload(true);
    const nodesClone = nodes.slice();
    let imageData;
    let imageUrls;
    try {
      const requests = nodes.map(async (node, index) => {
        const { id } = node;
        const extraImageParams = (scale: number) =>
          `/images/${fileKey}?ids=${id}&scale=${scale}`;

        imageData = await getFigmaData(extraImageParams(DEFAULT_PREVIEW_SCALE));
        let imageResponse = await imageData.json();
        let imageURL = imageResponse.images[id];

        const imageRequest = await fetch(imageURL);
        const imageBlob = await imageRequest?.blob();

        await new Promise(resolve =>
          setTimeout(resolve, index * DELAY_BETWEEN_REQUESTS)
        );

        if (imageBlob.size < MINIMUM_PREVIEW_SIZE) {
          imageData = await getFigmaData(extraImageParams(ORIGINAL_SCALE));
          imageResponse = await imageData.json();
          imageURL = imageResponse.images[id];
        }
        return { id, imageURL };
      });

      imageUrls = await Promise.all(requests);
    } catch (error) {
      handleError(imageData, error);
      setShowUpload(false);
      setIsFigmaModalOpen(false);
    }
    if (imageUrls?.length) {
      setNodes(
        nodesClone.map(node => ({
          ...node,
          imageUrl: imageUrls.find(({ id }) => id === node.id)?.imageURL
        }))
      );
    }
  };

  const updateNode = (_nodeID: string, toUpdate: ToUpdate) => {
    const nodesClone = nodes.slice();
    const _node = nodesClone.find(node => node.id === _nodeID);
    if (_node) Object.assign(_node, toUpdate);
    setNodes(nodesClone);
  };

  useEffect(() => {
    if (inputValue) {
      if (FIGMA_URL_PATTERN.test(inputValue)) {
        getNodesFromLink();
      } else {
        message.error(strings.invalidUrl);
      }
    }
  }, [inputValue]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    debounceInputOnChange(event.target.value);

  const uploadedImages = selectedNodes.filter(
    ({ progress }) => progress === 100
  );

  useEffect(() => {
    if (progress === 100) {
      setIsFigmaModalOpen(false);
      setNodes([]);
      setShowUpload(false);
    }
  }, [progress]);

  return (
    <FigmaUploadStateContext.Provider
      value={{
        nodes,
        setNodes,
        fileKey,
        updateNode,
        onInputChange,
        hasError,
        inputValue,
        getNodeImageURLs,
        loadingNodes,
        isLoading,
        showUpload,
        setShowUpload,
        selectedNodes,
        progress,
        uploadImages,
        handleSelect,
        isImagesLoading,
        uploadedImages,
        setFileKey
      }}
    >
      {children}
    </FigmaUploadStateContext.Provider>
  );
};

type FigmaUploadState = {
  nodes: FigmaNode[];
  setNodes: (nodes: FigmaNode[]) => void;
  handleClose: () => void;
  fileKey: string;
  updateNode: (nodeId: string, attributes: ToUpdate) => void;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void | undefined;
  hasError: boolean;
  inputValue: string;
  getNodeImageURLs: () => Promise<void>;
  loadingNodes: boolean;
  isLoading: boolean;
  showUpload: boolean;
  setShowUpload: (showUpload: boolean) => void;
  selectedNodes: FigmaNode[];
  progress: number;
  uploadImages: () => void;
  handleSelect: (isSelected: boolean) => void;
  isImagesLoading: boolean;
  uploadedImages: FigmaNode[];
  setFileKey: (fileKey: string) => void;
};

const useFigmaUploadState = (): FigmaUploadState => {
  const context = useContext(FigmaUploadStateContext);

  if (context === undefined) {
    throw new Error(
      'useFigmaUploadState must be used within a FigmaUploadProvider'
    );
  }

  // @ts-ignore
  return context;
};

export { FigmaUploadProvider, useFigmaUploadState, FigmaUploadState };
