import { useEffect, useState } from 'react';
import {
  getGlobalPreferences,
  setGlobalPreferences
} from '../../../utils/getPreferences';

export const useLoginlessDetails = ({
  firstName,
  email,
  onReturningUserChange,
  onEmailChange,
  onNameChange
}) => {
  const [name, _setName] = useState<string>(
    firstName && firstName !== 'Guest' ? firstName : null
  );
  const [emailAddress, _setEmailAddress] = useState<string>(
    email !== 'guest@bugherd.com' ? email : null
  );

  const setLoginlessPreferences = (preferences: {
    emailAddress?: string;
    firstName?: string;
  }) => {
    setGlobalPreferences(preferences);
  };

  const setName = (newName: string) => {
    setLoginlessPreferences({ firstName: newName });
    _setName(newName);
  };

  const setEmailAddress = (newEmail: string) => {
    setLoginlessPreferences({ emailAddress: newEmail });
    _setEmailAddress(newEmail);
  };

  useEffect(() => {
    const getUserPreferences = async () => {
      try {
        const getEmailAddress = await getGlobalPreferences('emailAddress');
        if (typeof getEmailAddress === 'string') {
          setEmailAddress(getEmailAddress);
          onEmailChange(getEmailAddress);
          onReturningUserChange();
        }

        const getFirstName = await getGlobalPreferences('firstName');
        if (typeof getFirstName === 'string') {
          onNameChange(getFirstName);
          setName(getFirstName);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    getUserPreferences();
  }, []);

  return {
    setName,
    setEmailAddress,
    name,
    emailAddress
  };
};
