import * as React from 'react';
import * as translations from './strings';
import styles from './index.module.css';

const strings = translations['en'];

type PlanHeaderProps = {
  plan: { name: string; price: number; interval: string };
  numAdditionalMembers: number;
  addonPremiumIntegrations: boolean;
  addonPremiumGuestFeatures: boolean;
  hiddenPrice?: boolean;
};

function formatInterval(interval: string): string {
  if (interval === 'Monthly') {
    return strings.perMonth;
  } else if (interval === 'Yearly') {
    return strings.perYear;
  } else {
    return '';
  }
}

const additionalMemberPrice: { [key: string]: number } = {
  Monthly: 8,
  Yearly: 80
};

const addonPremiumIntegrationsPrice: { [key: string]: number } = {
  Monthly: 15,
  Yearly: 150
};

const addonPremiumGuestFeaturesPrice: { [key: string]: number } = {
  Monthly: 20,
  Yearly: 200
};
const PlanHeader: React.FC<PlanHeaderProps> = ({
  plan,
  numAdditionalMembers,
  addonPremiumIntegrations,
  addonPremiumGuestFeatures,
  hiddenPrice
}: PlanHeaderProps) => {
  const planTitle =
    plan.name.replace('Yearly', '') +
    (numAdditionalMembers > 0
      ? ` + ${numAdditionalMembers} additional members`
      : '');
  return (
    <div className={styles.header}>
      <h2>{planTitle}</h2>
      {!hiddenPrice && (
        <div className={styles.paymentInfo}>
          <div className={styles.priceInfo}>
            USD
            <span className={styles.price}>
              {' '}
              $
              {(
                plan.price +
                (addonPremiumIntegrations
                  ? addonPremiumIntegrationsPrice[plan.interval]
                  : 0) +
                (addonPremiumGuestFeatures
                  ? addonPremiumGuestFeaturesPrice[plan.interval]
                  : 0) +
                numAdditionalMembers * additionalMemberPrice[plan.interval]
              ).toFixed(2)}
            </span>
          </div>
          <div className={styles.payPeriod}>
            {formatInterval(plan.interval)}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanHeader;
