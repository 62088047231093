import React, { FC } from 'react';
import { Select } from 'antd';
import * as translations from './strings';
import DetailsLabel from '../DetailsLabel';
// @ts-ignore
import styles from './styles.module.css';
import cx from 'classnames';
import { getLangKey } from '../../../../../../../clients/sidebar/models/Language';

const strings = translations[getLangKey()];

type Props = {
  updateSeverity: (value: number) => void;
  priorityId: number;
  canEditSeverity: boolean;
};

const priorities = ['Not set', 'Critical', 'Important', 'Normal', 'Minor'];

const Option = Select.Option;

const Severity: FC<Props> = ({
  priorityId,
  canEditSeverity,
  updateSeverity
}) => {
  return (
    <div className={styles.severityOuter}>
      <DetailsLabel label={strings.severity} />
      <Select
        className={cx(styles[`priority${priorityId}`])}
        // @ts-ignore
        value={priorities[priorityId]}
        disabled={!canEditSeverity}
        onChange={updateSeverity}
        getPopupContainer={trigger => trigger.parentElement}
      >
        {priorities.map((value, index) => (
          <Option
            className={cx(styles[`priority${index}`], styles.severityOption, {
              [styles[`active${index}`]]: `${index}` === `${priorityId}`
            })}
            key={value + index}
            value={index}
          >
            {value}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Severity;
