import * as React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import {
  format,
  isToday,
  isTomorrow,
  isYesterday,
  formatDistanceToNow
} from 'date-fns';

import { getLangKey } from 'models/Language';
import { OrganizationUser } from 'models/User';
import { Task } from 'models/Task';
import renderAvatarList from '../../../../../clients/shared/AvatarList';

import * as translations from '../strings';
import { UserTask } from '../types';
import styles from '../index.module.css';
import { User } from '../../../../../clients/sidebar/models/User';

const strings = translations[getLangKey()];

const getPopupContainer = () =>
  document.getElementById('ReactApp') as HTMLElement;

type DateObject = {
  formattedDate: string;
  isRelative: boolean;
};

const formatDate = (date: string, isDue?: boolean) => {
  const taskDate: Date = new Date(date);
  const dateObject: DateObject = { isRelative: true, formattedDate: '' };
  if (isDue) {
    if (isToday(taskDate)) {
      dateObject.formattedDate = strings.relativeToday;
    } else if (isTomorrow(taskDate)) {
      dateObject.formattedDate = strings.relativeTomorrow;
    } else if (isYesterday(taskDate)) {
      dateObject.formattedDate = strings.relativeYesterday;
    }
    if (dateObject.formattedDate) return dateObject;
  }

  dateObject.formattedDate = format(taskDate, strings.dateFormat);
  dateObject.isRelative = false;
  return dateObject;
};

export const projectName = (text: string, record: UserTask) => (
  <Tooltip
    getPopupContainer={getPopupContainer}
    placement="topLeft"
    title={record.project.name}
  >
    <span
      className={cx(
        styles.projNameCell,
        styles['priority-' + record.priorityId]
      )}
    >
      {record.project.name}
    </span>
  </Tooltip>
);

export const description = (text: string, task: Task) => (
  <Tooltip
    getPopupContainer={getPopupContainer}
    placement="topLeft"
    className={styles.descriptionCell}
    title={text}
  >
    <span>
      <span className={styles.localTaskId}>#{task.localTaskId}</span> {text}
    </span>
  </Tooltip>
);

export const status = (text: string) => (
  <Tooltip
    getPopupContainer={getPopupContainer}
    placement="topLeft"
    className={styles.statusCell}
    title={text}
  >
    <span>{text}</span>
  </Tooltip>
);

export const dueDate = (text: string) => {
  if (text) {
    const { formattedDate, isRelative } = formatDate(text, true);
    const date: string = formattedDate;
    return isRelative ? (
      <Tooltip
        title={<span>{formattedDate}</span>}
        placement="topLeft"
        getPopupContainer={getPopupContainer}
      >
        <span className={cx(styles.dueAtCell, [styles[date]])}>{date}</span>
      </Tooltip>
    ) : (
      <span className={styles.dueAtCell}>{date}</span>
    );
  }
  return null;
};

export const reported = (text: string) => {
  return (
    <span className={styles.createdAtCell}>
      {formatDate(text).formattedDate}
    </span>
  );
};

export const reportedBy = (
  _text: string,
  { requester: { name, email } }: UserTask
) => {
  const prefix = name ? `${name}: ` : '';

  return (
    <Tooltip
      title={[prefix, email].join('')}
      placement="topLeft"
      getPopupContainer={getPopupContainer}
    >
      <span className={styles.reportedByCell}>{name || email}</span>
    </Tooltip>
  );
};

export const updatedAt = (text: string) => {
  const lastUpdated = new Date(text);
  const { formattedDate } = formatDate(text);
  const today = isToday(lastUpdated);
  let distanceToNow = formatDistanceToNow(lastUpdated);
  distanceToNow =
    (distanceToNow.includes('less than')
      ? 'under' + distanceToNow.slice(9)
      : distanceToNow) + ' ago';

  return (
    <Tooltip
      placement="topLeft"
      getPopupContainer={getPopupContainer}
      title={today ? formattedDate : distanceToNow}
    >
      <span className={styles.updatedAtCell}>
        {today ? distanceToNow : formattedDate}
      </span>
    </Tooltip>
  );
};

export const assignedUsers = (assignedUsers: (OrganizationUser | User)[]) =>
  renderAvatarList({
    assignedUsers: assignedUsers.map(
      (assignedUser: OrganizationUser | User) =>
        ({
          name: assignedUser.name,
          id: assignedUser.id,
          avatarUrl: assignedUser.avatarUrl || '',
          role: assignedUser.role,
          surname: assignedUser.surname
        } as OrganizationUser | User)
    )
  });
