import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode
} from 'react';
import { get } from '../../redesign/javascript/utils/fetch';
import camelCaseKeys from 'camelcase-keys';
import { Project } from '../sidebar/models/Project';
import { User } from '../sidebar/models/User';
import {
  ParagonIntegrationData,
  WorkFlow
} from '../../javascript/components/Integrations/types';
import {
  Event,
  setupPusher,
  Subscription,
  teardownPusher
} from '../../javascript/models/Pusher';
import { useCurrentUserState } from './CurrentUser';

// @ts-ignore
const ProjectStateContext = createContext<ProjectState>();

type EmbeddedBy = 'javascript' | 'extension';

type Subscribed = 'some' | 'all' | 'none';

const ProjectProvider = ({
  apiKey,
  apiDomain,
  embeddedBy,
  id,
  children
}: {
  apiKey?: string;
  apiDomain?: string;
  children: ReactNode;
  id?: string;
  embeddedBy?: EmbeddedBy;
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [project, setProject] = useState<Project | null>(null);
  const [subscribed, setSubscribed] = useState<Subscribed>('none');

  const { currentUser, updateCurrentUser } = useCurrentUserState();

  const parseAndSetProject = (data: any) => {
    setProject(p => {
      return Object.assign({}, p, camelCaseKeys(data, { deep: true }));
    });
  };
  const fetchProject = ({
    id,
    embeddedBy,
    apiKey
  }: {
    id?: string;
    embeddedBy?: EmbeddedBy;
    apiKey: string;
  }) =>
    new Promise<Project>((resolve, reject) => {
      setLoading(true);

      const projectReqQueryParams = {
        id,
        source: embeddedBy,
        api_key: apiKey
      };

      // we stringify and parse as json to remove undefined vals, i.e. `embeddedBy=undefined`
      const projectReqQueryParamsString = new URLSearchParams(
        JSON.parse(JSON.stringify(projectReqQueryParams))
      );

      get(`${apiDomain}/projects/full?${projectReqQueryParamsString}`).then(
        data => {
          const newProject = camelCaseKeys(data, { deep: true });

          parseAndSetProject(data);

          if (newProject.paragonEnabled) {
            const projectsEndpoint = `${apiDomain}/projects/${newProject.id}`;
            get(`${projectsEndpoint}/get_paragon_user`)
              .then(res => {
                parseAndSetProject({ paragonIntegrations: res.integrations });
              })
              .catch(err => console.error(error));

            get(`${projectsEndpoint}/get_paragon_workflows`)
              .then(res => {
                if (Array.isArray(res)) {
                  parseAndSetProject({
                    paragonIntegrationsData: res.map(
                      ({
                        type,
                        isActive,
                        workflows
                      }: ParagonIntegrationData) => ({
                        type,
                        isActive,
                        workflows: workflows.map(
                          ({ id, description }: WorkFlow) => ({
                            id,
                            description
                          })
                        )
                      })
                    )
                  });
                }
              })
              .catch(error => console.error(error));
          }

          setLoading(false);

          // warning: this resolves with the project from the server, EXC. integrations etc above.
          resolve(newProject);
        }
      );
    });

  const handleGuestEditTaskStatusUpdate = (pusherData: any) => {
    if (currentUser.role === 'guest') {
      updateCurrentUser({
        accessTo: Object.assign(currentUser.accessTo, {
          taskEditStatus: pusherData.guest_edit_task_status
        })
      });
    }
  };

  const setupPusherEvents = (
    events: { name: Event; onUpdate: (pusherData: any) => void }[]
  ) => {
    if (project) {
      setupPusher({
        config: {
          pusherApiKey: project.pusherKey,
          pusherChannelAuthEndpoint: project.pusherChannelAuthEndpoint
        },
        events,
        subscription: Subscription.USER_PROJECTS,
        projects: [
          {
            id: project.id.toString(),
            pusherChannelName: project.pusherChannelName
          }
        ]
      });
    }
  };

  useEffect(() => {
    fetchProject({ embeddedBy, apiKey, id }).catch(err => {
      console.log({ err });
      setError(err);
    });

    return () => {
      if (project) {
        teardownPusher({
          config: {
            pusherApiKey: project.pusherKey,
            pusherChannelAuthEndpoint: project.pusherChannelAuthEndpoint
          },
          projects: [
            {
              id: project.id.toString(),
              pusherChannelName: project.pusherChannelName
            }
          ],
          subscription: Subscription.USER_PROJECTS
        });
      }
    };
  }, []);

  useEffect(() => {
    if (subscribed === 'none') {
      setupPusherEvents([
        {
          name: Event.PROJECT_UPDATE,
          onUpdate: ({ project }) => {
            parseAndSetProject(project);
          }
        },
        {
          name: Event.PROJECT_TAGS_UPDATED,
          onUpdate: ({ payload }) => {
            parseAndSetProject({ tags: payload });
          }
        }
      ]);
      setSubscribed('some');
    }
  }, [project]);

  useEffect(() => {
    if (currentUser && project && subscribed !== 'all') {
      setupPusherEvents([
        {
          name: Event.GUEST_EDIT_STATUS_UPDATED,
          onUpdate: handleGuestEditTaskStatusUpdate
        }
      ]);
      setSubscribed('all');
    }
  }, [currentUser, subscribed, project]);

  const getUser = (id?: number, name?: string): User => {
    const user = {
      name: name || 'anonymous',
      avatar: `${apiDomain}/images/sidebar/avatar-generic.svg`,
      id: -1,
      role: 'anonymous'
    };
    if (typeof id !== 'number' || !project) {
      return user;
    }

    return (
      project.users.find(_user => _user.id === id) ||
      project.usersNotOnProject.find(_user => _user.id === id) ||
      user
    );
  };

  return (
    <ProjectStateContext.Provider
      value={{
        loading,
        project,
        getUser,
        setProject
      }}
    >
      {children}
    </ProjectStateContext.Provider>
  );
};

type ProjectState = {
  loading: boolean;
  error: any;
  project: Project;
  getUser: (id?: number, name?: string) => User;
  setProject: (project: Project) => void;
  paragonIntegrationsData: ParagonIntegrationData[];
};

const useProjectState = (): ProjectState => {
  const context = useContext(ProjectStateContext);

  if (context === undefined) {
    throw new Error('useProjectState must be used within a ProjectProvider');
  }

  // @ts-ignore
  return context;
};

export { ProjectProvider, useProjectState, ProjectState };
