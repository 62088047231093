import React, { FC } from 'react';
import { Radio, Tooltip, Button } from 'antd';
import styles from './styles.module.css';
import * as translations from './strings';
import { RefreshCw, AlertCircle } from 'lucide-react';
import { getLangKey } from '../../../../../clients/sidebar/models/Language';
import Loader from '../Loader';

const strings = translations[getLangKey()];

type Props = {
  handleOnChange: (isTaskInfo: boolean) => void;
  isTaskInfo: boolean;
  isLoadingParagonIntegrations: boolean;
  integrationsError: string;
  container: HTMLDivElement;
  getIntegrationsInformation: () => void;
};

const DetailsTab: FC<Props> = ({
  handleOnChange,
  isTaskInfo,
  isLoadingParagonIntegrations,
  integrationsError,
  container,
  getIntegrationsInformation
}) => {
  const getIntegrationsLabelIcon = () => {
    if (isLoadingParagonIntegrations)
      return (
        <Loader useDarkStyles={isTaskInfo} className={styles.loaderIcon} />
      );
    if (integrationsError)
      return (
        <Tooltip
          overlayClassName={styles.tooltip}
          getPopupContainer={() => container}
          placement="bottomLeft"
          title={
            <span className={styles.tooltipTitle}>
              {integrationsError}
              <Button
                type="default"
                size="small"
                className={styles.refresh}
                onClick={() => getIntegrationsInformation()}
              >
                {strings.refresh}
                <RefreshCw className={styles.refreshIcon} />
              </Button>
            </span>
          }
        >
          <AlertCircle className={styles.alertIcon} />
        </Tooltip>
      );
  };

  return (
    <Radio.Group
      options={[
        { label: strings.taskInfo, value: true },
        {
          label: (
            <span className={styles.integrationsLabel}>
              {strings.integrations}
              {getIntegrationsLabelIcon()}
            </span>
          ),
          value: false
        }
      ]}
      onChange={event => handleOnChange(event.target.value as boolean)}
      value={isTaskInfo}
      optionType="button"
      buttonStyle="solid"
      className={styles.detailsRadio}
    />
  );
};

export default DetailsTab;
