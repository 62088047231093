import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cx from 'classnames';

import Avatar from '../Avatar';
import AvatarWrapper from '../Avatar/AvatarWrapper';
import { User } from '../../sidebar/models/User';

import styles from './index.module.css';
import { OrganizationUser } from '../../../redesign/javascript/models/User';

type AssignedUser = User | OrganizationUser;
interface AvatarListProps {
  assignedUsers: AssignedUser[];
  container?: HTMLDivElement;
}

const AvatarList: FC<AvatarListProps> = ({ container, assignedUsers }) => {
  if (!assignedUsers.length) {
    return null;
  }
  const getPopupContainer = () =>
    container || document.getElementById('ReactApp');
  const totalUsersToShow = assignedUsers.length === 3 ? 3 : 2;

  const leadingAssignees: AssignedUser[] = assignedUsers.slice(
    0,
    totalUsersToShow
  );
  const remainingAssignees: AssignedUser[] = assignedUsers.slice(
    totalUsersToShow
  );
  const totalRemaining: number = remainingAssignees.length;
  const remainingTitle: string = remainingAssignees
    .map((user: AssignedUser) => user.name)
    .join(', ');
  return (
    <div className={styles.assigneesOuter}>
      {leadingAssignees.map(
        (
          {
            avatar,
            avatarUrl,
            name,
            id,
            role,
            first_name,
            firstName,
            surname,
            email
          }: AssignedUser,
          index: number
        ) => {
          return (
            <Tooltip
              title={name}
              // @ts-expect-error
              getPopupContainer={getPopupContainer}
              placement="topLeft"
              key={id}
            >
              <div
                className={cx(styles.avatarContainer, {
                  [styles.firstOverlap]: index === 1,
                  [styles.secondOverlap]: index === 2
                })}
              >
                <Avatar
                  member={{
                    avatar: avatar || avatarUrl || '',
                    name,
                    role,
                    firstName: firstName || first_name,
                    surname,
                    email
                  }}
                  size="small"
                />
              </div>
            </Tooltip>
          );
        }
      )}
      {!!totalRemaining && (
        <Tooltip
          title={<span>{remainingTitle}</span>}
          // @ts-expect-error
          getPopupContainer={getPopupContainer}
          placement="topLeft"
        >
          <div className={cx(styles.avatarContainer, styles.secondOverlap)}>
            <AvatarWrapper size="small" color="pink">
              {`${totalRemaining}+`}
            </AvatarWrapper>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default AvatarList;
