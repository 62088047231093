import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Modal, message } from 'antd';
import Avatar from '../../../../../clients/shared/Avatar';
import cx from 'classnames';
import styles from './index.module.css';

export default class EditAccess extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    member: PropTypes.object,
    container: PropTypes.func,
    authenticityToken: PropTypes.string.isRequired,
    toggleModal: PropTypes.func,
    endpoint: PropTypes.string,
    onUpdateUser: PropTypes.func,
    projects: PropTypes.array.isRequired
  };

  componentDidMount() {
    message.destroy();
  }

  UNSAFE_componentWillMount() {
    message.config({
      getContainer: this.props.container
    });
  }

  projectsMemberCanAccess = props => {
    return props.projects.reduce((acc, project) => {
      acc[project.id] = props.member.on_projects.includes(project.id);
      return acc;
    }, {});
  };

  state = {
    saving: false,
    hasAccess: this.projectsMemberCanAccess(this.props),
    show: false
  };

  handleSelectAll = action => {
    let hasAccess = this.state.hasAccess;
    Object.keys(hasAccess).map(projectId => {
      if (action && !hasAccess[projectId]) {
        hasAccess[projectId] = true;
      } else if (!action) {
        hasAccess[projectId] = false;
      }
    });
    this.setState({
      hasAccess
    });
  };

  handleCheckboxOnChange = (event, projectId) => {
    let hasAccess = this.state.hasAccess;
    hasAccess[projectId] = event.target.checked;
    this.setState({
      hasAccess
    });
  };

  saveChanges = member => {
    const { id } = member;
    const displayName = member.display_name;
    const { endpoint, authenticityToken } = this.props;
    const { hasAccess } = this.state;
    const formData = new FormData();

    formData.append('utf8', '✓');
    formData.append('authenticity_token', authenticityToken);

    Object.keys(hasAccess).forEach(projId => {
      if (hasAccess[projId]) {
        formData.append('project[]', projId);
      }
    });
    this.setState({
      saving: true
    });

    fetch(`${endpoint}?user_id=${id}`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    })
      .then(response => {
        this.setState({
          saving: false
        });

        if (response.status !== 200 && response.status !== 302) {
          throw Error(response.statusText);
        } else {
          message.success(
            `Project access has been updated for ${displayName}.`
          );
          this.setState({
            show: false
          });

          const _member = Object.assign({}, member);

          _member.on_projects = this.props.projects.map(project =>
            Object.assign(project, {
              hasAccess: hasAccess[project.id]
            })
          );

          this.props.onUpdateUser(_member);
          this.props.toggleModal();
        }
      })
      .catch(error => message.error('Something went wrong. Please try again.'));
  };

  sortedProjects = () => {
    return this.props.projects.slice(0).sort((a, b) => {
      const aDisplayName = a.name.toLowerCase();
      const bDisplayName = b.name.toLowerCase();
      if (aDisplayName < bDisplayName) {
        return -1;
      }
      if (aDisplayName > bDisplayName) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    const { member, container, toggleModal } = this.props;

    return (
      <Modal
        open={this.props.show}
        footer={null}
        className={styles.modalContainer}
        getContainer={container}
        title="Edit Project Access"
        onCancel={this.props.toggleModal}
      >
        <div className={styles.modalContainer}>
          <div className={styles.userHeader}>
            <Avatar member={member} />
            <span className={styles.membersName}>{member.display_name}</span>
          </div>
          <div className={styles.accessContainer}>
            <h3 className={styles.accessHeader}>
              {`${member.display_name} has access to the selected projects below.`}
            </h3>
            <div className={styles.selectButtonsBox}>
              <span
                className={cx([styles.selectAll, styles.select])}
                onClick={() => this.handleSelectAll(true)}
              >
                Select all
              </span>
              <span className={styles.buttonDivider}>|</span>
              <span
                className={cx([styles.selectNone, styles.select])}
                onClick={() => this.handleSelectAll(false)}
              >
                Select none
              </span>
            </div>
            <div className={styles.projects}>
              {this.sortedProjects().map(project => (
                <div className={styles.projectBox} key={project.id}>
                  <Checkbox
                    checked={this.state.hasAccess[project.id]}
                    onChange={event =>
                      this.handleCheckboxOnChange(event, project.id)
                    }
                    key={project.id}
                  >
                    <span className={styles.checkboxLabel}>
                      {project.enabled
                        ? project.name
                        : `${project.name} (Disabled)`}
                    </span>
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className={styles.buttonsBox}>
              <Button
                className={styles.saveButton}
                type="primary"
                size="large"
                onClick={() => this.saveChanges(member)}
                loading={this.state.saving}
              >
                <span className={styles.primaryChild}>Save</span>
              </Button>
              <Button
                className={styles.cancelButton}
                type="secondary"
                size="large"
                onClick={toggleModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
