import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
  SetStateAction,
  Dispatch
} from 'react';
import { getProjectSites } from '../../redesign/clients/design_assets/Sites/ProjectSites';
import { Website } from '../../redesign/clients/design_assets/types';

interface ProjectSitesContextType {
  isProjectSitesLoading: boolean;
  projectSites: Website[];
  setProjectSites: Dispatch<SetStateAction<Website[]>>;
  handleGetProjectSites: () => void;
  proxyHost: string;
}

const ProjectSitesContext = createContext<ProjectSitesContextType | undefined>(
  undefined
);

type ProjectSitesProviderProps = {
  apiKey: string;
  projectId: string;
  children: ReactNode;
  proxyHost: string;
};

const ProjectSitesProvider = ({
  apiKey,
  projectId,
  children,
  proxyHost
}: ProjectSitesProviderProps) => {
  const [isProjectSitesLoading, setIsProjectSitesLoading] = useState<boolean>(
    true
  );
  const [projectSites, setProjectSites] = useState<Website[]>([]);

  const handleGetProjectSites = async () => {
    try {
      const response = await getProjectSites(apiKey, projectId);
      if (JSON.stringify(response.sites) !== JSON.stringify(projectSites)) {
        setProjectSites(response.sites);
      }
    } catch (e) {
      //@ts-ignore
      // eslint-disable-next-line no-unused-expressions
      window?.bugsnag(e, 'Error getting project sites');
      console.error(e);
    } finally {
      setIsProjectSitesLoading(false);
    }
  };

  useEffect(() => {
    handleGetProjectSites();
  }, []);

  return (
    <ProjectSitesContext.Provider
      value={{
        isProjectSitesLoading,
        projectSites,
        setProjectSites,
        handleGetProjectSites,
        proxyHost
      }}
    >
      {children}
    </ProjectSitesContext.Provider>
  );
};

const useProjectSitesState = () => {
  const context = useContext(ProjectSitesContext);
  if (context === undefined) {
    throw new Error(
      'useProjectSites must be used within a IntegrationsProvider'
    );
  }
  return context;
};

export { ProjectSitesProvider, useProjectSitesState };
